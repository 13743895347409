<template>
  <v-col class="lesson-pane" :id="id">
    <div class="accordion-trigger">
      <div class="left-pane" @click.prevent="triggerSelect">
        <rotating-button :isActive="selected"></rotating-button>
        <div class="section-text d-flex" col="6">
          <div class="lesson-section">
            <span class="pa-2 ml-3 mt-4 rounded-lg">{{ lesson.sectionDetails.name }}</span>
          </div>
          <div class="section-text-head">
            <div><h3>{{ lesson.name }}</h3></div>
          </div>
          <div v-if="lesson.memo" class="lesson-memo">
            <span class="pa-2 ml-3 mt-4 rounded-lg">{{ lesson.memo }}</span>
          </div>
        </div>
      </div>
      <div class="right-pane">
        <v-col class="d-flex text-right" col="6">
          <h2 v-if="hasIndependentLesson || hasPracticeSet">
            <strong>{{ activitiesCompleted }}/{{ activitiesCount }}</strong>
            Activities Complete
          </h2>
          <h2 v-else>
            No Activities to Complete
          </h2>
        </v-col>
      </div>
    </div>
    <div class="lessons px-2 pb-2" :hidden="!selected">
      <v-row class="lesson-items text-center rounded-lg">
        <v-col v-if="showDefaultRow" cols="12">
          <span>No Activity Materials available</span>
        </v-col>
        <v-col outlined class="item-details" v-if="hasIndependentLesson">
          <h3>Independent Lesson</h3>
          <h3 v-if="independentLessonComplete">{{ independentLessonPercentCorrect }}% Correct</h3>
          <v-row justify="center" align="center" class="mt-0">
            <v-col cols="12" sm="auto">
              <v-btn
                dark
                class="btn-default btn-default__secondary ml-3"
                @click="launchIndependentLesson()"
              >
                <v-img
                  class="mr-2"
                  contain
                  src="@/assets/icons/courseactivities_orange.png"
                  alt="icon-add"
                  max-width="15"
                  max-height="15"
                />
                <div v-if="independentLessonComplete">
                  Review Lesson
                </div>
                <div v-else>
                  View Lesson
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col outlined class="item-details" v-if="hasPracticeSet">
          <h3>Practice Set</h3>
          <h3 v-if="practiceSetComplete">{{ practiceSetPercentCorrect }}% Correct</h3>
          <v-row justify="center" align="center" class="mt-0">
            <v-col cols="12" sm="auto">
              <v-btn
                dark
                class="btn-default btn-default__secondary ml-3"
                @click="launchPracticeSet()"
              >
                <v-img
                  class="mr-2"
                  contain
                  src="@/assets/icons/test_1.png"
                  alt="icon-add"
                  max-width="15"
                  max-height="15"
                />
                <div v-if="practiceSetComplete">
                  Review Lesson
                </div>
                <div v-else>
                  View Practice Set
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col outlined class="item-details" v-if="hasSlidePresentation">
          <h3>Slide Presentation</h3>
          <v-row justify="center" align="center" class="mt-0">
            <v-col cols="12" sm="auto">
              <v-btn
                dark
                class="btn-default btn-default__secondary ml-3"
                @click="viewPresentation()"
              >
                <v-img
                  class="mr-2"
                  contain
                  src="@/assets/icons/presentation.png"
                  alt="icon-add"
                  max-width="15"
                  max-height="15"
                />
                View Presentation
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col outlined class="item-details" v-if="hasSupportVideo">
          <h3>Support Video</h3>
          <v-row justify="center" align="center" class="mt-0">
            <v-col cols="12" sm="auto">
              <v-btn
                dark
                class="btn-default btn-default__secondary ml-3"
                @click="viewVideo()"
              >
                <v-img
                  class="mr-2"
                  contain
                  src="@/assets/icons/video.png"
                  alt="icon-add"
                  max-width="15"
                  max-height="15"
                />
                View Video
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <dialog-lesson-material
      :title="lessonMaterialTitle"
      :active="dialogLessonMaterial"
      :media_src="lessonMaterialSource"
      :btnTxt = "lessonMaterialBtnTxt"
      @closeDialog="closeDialogLessonMaterial()"
    />
    <horizon-modal-full-width
      modal-title="Review Activity Responses"
      :active="reviewModal"
      :on-close="reviewModalClose">
      <template v-slot:content>
        <div :id="id + '-review-container'">
          <div :id="id + '-review'"></div>
        </div>
      </template>
    </horizon-modal-full-width>
  </v-col>
</template>

<script>
import RotatingButton from '@/components/shared/course_activities/rotating_button.vue'
import DialogLessonMaterial from './shared/DialogLessonMaterial.vue'
import HorizonModalFullWidth from '@/components/shared/HorizonModalFullWidth.vue'
import { StudentCourseActivityApi } from '@/api'

export default {
  components: {
    RotatingButton,
    DialogLessonMaterial,
    HorizonModalFullWidth
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    lesson: {
      type: Object,
      default() { return {} }
    },
  },
  data() {
    return {
      selected: false,
      dialogLessonMaterial: false,
      lessonMaterialTitle: '',
      lessonMaterialSource: '',
      lessonMaterialBtnTxt: '',
      reviewModal: false,
      reviewModalHtmlTag: '',
      reviewModalInitConf: {}
    }
  },
  watch: {
    reviewModalHtmlTag: {
      immediate: true,
      handler(val) {
        if (document.getElementById(this.id + "-review-container") == null) { return }
        this.clearReportContainer(val)
      }
    },
    reviewModalInitConf: {
      immediate: true,
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.loadReportHtml()
        }
      }
    }
  },
  methods: {

    reviewModalClose() {
      this.reviewModal = false
      this.reviewModalHtmlTag = ''
      this.reviewModalInitConf = {}
    },
    clearReportContainer(newId) {
      let container = document.getElementById(this.id + "-review-container")
      let old_div = container.firstElementChild
      if (old_div != null) {
        let new_div = document.createElement("div")
        new_div.id = newId
        old_div.parentNode.replaceChild(new_div, old_div)
      }
    },
    loadReportHtml() {
      setTimeout(() => {
        window.LearnosityReports.init(this.reviewModalInitConf)
      }, 500)
    },
    async launchActivityReview(activity_id) {
      this.reviewModal = true

      const res = await StudentCourseActivityApi.getReviewQuestions(
        this.lesson.id,
        activity_id
      )

      let config = JSON.parse(res.response)
      this.reviewModalHtmlTag = config.request.reports[0].id
      this.reviewModalInitConf = config
    },
    launchActivity(activity_id) {
      this.$router.push({
        name: 'StudentCourseLearnosityActivity',
        params: {
          lessonId: this.lesson.id,
          activityId: activity_id
        }
      })
    },


    hasResponses () {
      return this.hasProperty(this.lesson, 'lessonResponses')
    },
    triggerSelect() {
      this.selected = this.selected == true ? false : true
    },
    openDialogLessonMaterial(){
      this.dialogLessonMaterial = true
    },
    closeDialogLessonMaterial(){
      this.dialogLessonMaterial = false
      this.lessonMaterialSource = ''
    },
    setSourceDialogLessonMaterial(source){
      this.lessonMaterialSource = source
    },
    setLessonMaterialTitle(title){
      this.lessonMaterialTitle = title
    },
    viewPresentation(){
      this.setSourceDialogLessonMaterial(this.lesson.supportMaterials.google.url)
      this.setLessonMaterialTitle('Google Slide Presentation')
      this.lessonMaterialBtnTxt = 'Slide'
      this.openDialogLessonMaterial()
    },
    viewVideo(){
      this.setSourceDialogLessonMaterial(this.lesson.supportMaterials.supportVideos.url)
      this.setLessonMaterialTitle('Support Video')
      this.lessonMaterialBtnTxt = 'Video'
      this.openDialogLessonMaterial()
    },
    async launchIndependentLesson() {
      if (this.independentLessonComplete) {
        this.launchActivityReview(this.lesson.learnosityActivityBanks.independentLesson)
      } else {
        this.launchActivity(this.lesson.learnosityActivityBanks.independentLesson)
      }
    },
    async launchPracticeSet() {
      if (this.practiceSetComplete) {
        this.launchActivityReview(this.lesson.learnosityActivityBanks.practiceSet)
      } else {
        this.launchActivity(this.lesson.learnosityActivityBanks.practiceSet)
      }
    },
  },
  computed: {
    showDefaultRow() {
      const activityBanks = this.lesson.learnosityActivityBanks || {};
      const supportMaterials = this.lesson.supportMaterials || {};

      return (
        !activityBanks.independentLesson &&
        !activityBanks.practiceSet &&
        !supportMaterials.google?.url &&
        !supportMaterials.supportVideos?.url
      );
    },
    hasIndependentLesson() {
      return this.hasProperty(this.lesson.learnosityActivityBanks, 'independentLesson')
        && !isNaN(this.lesson.learnosityActivityBanks.independentLesson)
    },
    hasPracticeSet() {
      return this.hasProperty(this.lesson.learnosityActivityBanks, 'practiceSet')
        && !isNaN(this.lesson.learnosityActivityBanks.practiceSet)
    },
    hasSlidePresentation() {
      return this.hasProperty(this.lesson.supportMaterials, 'google')
        && this.hasProperty(this.lesson.supportMaterials.google, 'url') ;
    },
    hasSupportVideo() {
      return this.hasProperty(this.lesson.supportMaterials, 'supportVideos')
        && this.hasProperty(this.lesson.supportMaterials.supportVideos, 'url');
    },
    practiceSetPercentCorrect() {
      if (!this.hasResponses()) {
        return 0
      }
      if (this.hasProperty(this.lesson.lessonResponses.responses, 'practice_set')) {
        if (this.lesson.lessonResponses.responses.practice_set.max_score == 0 && this.lesson.lessonResponses.responses.practice_set.status == 'Completed') {
          return 100
        }
        if (this.lesson.lessonResponses.responses.practice_set.score > 0) {
          return Math.round((this.lesson.lessonResponses.responses.practice_set.score/this.lesson.lessonResponses.responses.practice_set.max_score) * 100)
        }
      }
      return 0
    },
    independentLessonPercentCorrect() {
      if (!this.hasResponses()) {
        return 0
      }
      if (this.hasProperty(this.lesson.lessonResponses.responses, 'independent_lesson')) {
        if (this.lesson.lessonResponses.responses.independent_lesson.max_score == 0 && this.lesson.lessonResponses.responses.independent_lesson.status == 'Completed') {
          return 100
        }
        if (this.lesson.lessonResponses.responses.independent_lesson.score > 0) {
          return Math.round((this.lesson.lessonResponses.responses.independent_lesson.score/this.lesson.lessonResponses.responses.independent_lesson.max_score) * 100)
        }
      }
      return 0
    },
    activitiesCount() {
      let count = 0
      if (this.hasProperty(this.lesson.learnosityActivityBanks, 'independentLesson')
        && this.lesson.learnosityActivityBanks.independentLesson) { count += 1 }
      if (this.hasProperty(this.lesson.learnosityActivityBanks, 'practiceSet')
        && this.lesson.learnosityActivityBanks.practiceSet) { count += 1 }
      return count
    },
    activitiesCompleted() {
      if (!this.hasResponses()) {
        return 0
      }
      let count = 0
      if (this.hasProperty(this.lesson.lessonResponses.responses, 'independent_lesson')
        && this.lesson.lessonResponses.responses.independent_lesson.status == 'Completed') { count += 1 }
      if (this.hasProperty(this.lesson.lessonResponses.responses, 'practice_set')
        && this.lesson.lessonResponses.responses.practice_set.status == 'Completed') { count += 1 }
      return count
    },
    independentLessonComplete() {
      return this.hasProperty(this.lesson.lessonResponses.responses, 'independent_lesson')
        && this.lesson.lessonResponses.responses.independent_lesson.status == 'Completed'
    },
    practiceSetComplete() {
      return this.hasProperty(this.lesson.lessonResponses.responses, 'practice_set')
        && this.lesson.lessonResponses.responses.practice_set.status == 'Completed'
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.lessons {
  padding-top: 25px;
  width: 100%;
}

.accordion-trigger {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .left-pane {
    display: flex;
    width: 100%;
    align-items: center;
    .section-text {
      margin-left: 10px;
    }
  }
  .right-pane {
    a {
      width: max-content;
      border-color: #c7c7c7;
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    a:hover {
      border-color: #345165;
      color: #345165;
    }
    h2 {
      width: max-content;
      font-size: 18px;
      font-weight: 400;
      strong {
        color: #f4701f;
        font-weight: 700;
      }
    }
  }
}
.lesson-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  cursor: auto;
  border: 1px solid #eaecf0;
  border-radius: 14px;
  margin: 10px 0px 10px 0px;
  background-color: #f2f4f8;
  h1 {
    font-size: 18px;
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
  span {
    font-size: 12px;
    line-height: 16px;
  }
}
.section-text {
  .lesson-memo {
    padding: 10px 10px 10px 0px;

    span {
      background-color: #d7e2eb;
    }
  }
  .lesson-section {
    padding: 10px 10px 10px 0px;

    span {
      background-color: #d7ebe2;
    }
  }
  .section-text-head {
    padding: 5px;
    min-height: 15px;
    font-size: 16px;
  }
}
.drag-icon {
  width: 25px;
  height: 25px;
  margin-left: -20px;
}
.lesson-time {
    color: #3d5d74;
    font-weight: 600;
}

.lesson-items {
  background-color: #fff;
  .item-details {
    border-color: #f2f4f8;
    border-style: solid;
    border-width: 1px;
  }
}

</style>

import HorizonApi from '@/api/horizon_api'
const STAFF_SCHOOL_STUDENT_RESULTS_PATH = '/api/staff/school/student_results'


export default {
  getDashboardSummary() {
    return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/dashboard_summary`)
  },

  getDashboardSummaryDetails({ assessmentTypeId, assessmentSetId, page, perPage, search, order } = {}) {
    const params = {
      params: {
        assessment_type_id: assessmentTypeId,
        assessment_set_id: assessmentSetId,
        page: page,
        per_page: perPage,
        search: search,
        order: order
      }
    }

    return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/dashboard_summary_details`, params)
  },

  getOverview ({ assessmentSetId, assessmentTypeId, sectionId } = {}) {
    const params = {
      params: {
        assessment_set_id: assessmentSetId,
        assessment_type_id: assessmentTypeId
      } 
    }

    if (sectionId == null) {
      return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}`, params)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_results`, params)
    }
  },

  getCategoryTabs ({ assessmentSetId, assessmentTypeId, sectionId } = {}) {
    const params = {
      params: {
        assessment_set_id: assessmentSetId,
        assessment_type_id: assessmentTypeId
      } 
    }

    if (sectionId == null) {
      return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/categories`, params)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_results/categories`, params)
    }
  },

  getCategoryDetails ({ assessmentId, sectionId } = {}) {
    let params = {
      params: {
        assessment_id: assessmentId
      }
    }

    if (sectionId == null) {
      return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/category_details`, params)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_results/category_details`, params)
    }
  },

  getIndividualStudentPerformance (sectionId, assessmentSetId, assessmentTypeId, page = 1, perPage, sort, order, searchProp, filters) {
    const params = {
      'assessment_set_id': assessmentSetId,
      'assessment_type_id': assessmentTypeId,
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
      'filters': filters
    }

    if (sectionId == null) {
      return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/individual_student_performance`, {params: params})
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_results/individual_student_performance`, {params: params})
    }
  }, 

  learnosityResponseAnalysisByItem ({ assessmentId, sectionIds, gradeLevels, schoolYear, page, query } = {}) {
    let params = {
      params: {
        assessment_id: assessmentId,
        school_year_id : schoolYear,
        section_ids: sectionIds,
        grade_levels: gradeLevels,
        page: page,
        query: query
      }
    }

    return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/learnosity_response_analysis_by_item`, params)
  },

  getStudentResultsClasses({ assessmentTypeId, assessmentSetId } = {}) {
    const params = {
      params: {
        assessment_type_id: assessmentTypeId,
        assessment_set_id: assessmentSetId,
      }
    }

    return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/sections_by_assessment`, params)
  },
  
  getTopicAreaBreakdown({ assessmentId, topicAreaId, sectionId }) {
    let params = {
      params: {
        assessment_id: assessmentId,
        topic_area_id: topicAreaId
      }
    }

    if (sectionId == null) {
      return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/topic_area_breakdown`, params)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_results/topic_area_breakdown`, params)
    }
  },

  getTopicAreaBreakdownForStudent({ studentAssessmentId, topicAreaId, sectionId, multipart }) {
    let params = {
      params: {
        student_assessment_id: studentAssessmentId,
        topic_area_id: topicAreaId,
        multipart: multipart
      }
    }

    if (sectionId == null) {
      return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/topic_area_breakdown`, params)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_results/topic_area_breakdown`, params)
    }
  },

  getReportsSummary({assessmentTypeId, assessmentSetId, sectionId, gradeLevels, schoolYear, } = {}) {
    const params = {
      params: {
        assessment_type_id: assessmentTypeId,
        assessment_set_id: assessmentSetId,
        section_ids : sectionId,
        grade_levels: gradeLevels,
        school_year_id : schoolYear,
      }
    }
    return HorizonApi.get(STAFF_SCHOOL_STUDENT_RESULTS_PATH, params)
  },

  getReportsSubsectionCategoryTabs ({ assessmentSetId, assessmentTypeId } = {}) {
    const params = {
      params: {
        assessment_type_id: assessmentTypeId,
        assessment_set_id: assessmentSetId,
      }
    }
    return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/categories`, params)
  },

  getReportsSubsectionCategoryDetails ({ assessmentId, gradeLevels, schoolYear, sections } = {}) {
    const params = {
      params: {
        assessment_id: assessmentId ,
        grade_levels: gradeLevels,
        school_year_id: schoolYear,
        section_ids: sections,
      }
    }
    return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/category_details`, params)
  },

  getReportsSubsectionTopicAreaBreakdown({ assessmentId, topicAreaId, gradeLevels, schoolYear, sections }) {
    let params = {
      params: {
        assessment_id: assessmentId,
        topic_area_id: topicAreaId,
        grade_levels: gradeLevels,
        school_year_id : schoolYear,
        section_ids : sections
      }
    }
     return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/topic_area_breakdown`, params)
  },

  getReportsStudentPerformance ({assessmentSetId, assessmentTypeId, gradeLevels, schoolYear, sections, page = 1, perPage, sort, order, searchProp, filters} = {}) {
    const params = {
      'assessment_set_id': assessmentSetId,
      'assessment_type_id': assessmentTypeId,
      'grade_levels': gradeLevels,
      'school_year_id' : schoolYear,
      'section_ids' : sections,
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
      'filters': filters,
    }

    return HorizonApi.get(`${STAFF_SCHOOL_STUDENT_RESULTS_PATH}/individual_student_performance`, {params: params})
  },

}
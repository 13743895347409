<template>
  <div>
    <v-container
      fluid
      :class="editMode ? 'pt-1' : undefined"
    >
      <app-header
        v-if="!editMode"
        title="Create New School"
      />
      <v-row :class="editMode ? undefined : 'mt-1'">
        <v-col cols="12">
          <h3>School Information</h3>
        </v-col>
      </v-row>
      <v-form class="form-default">
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="name"
              >
                School Name
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-0 pt-sm-3"
          >
            <v-text-field
              id="name"
              v-model="school.name"
              :hide-details="!$v.school.name.$error"
              error-messages="Please enter a valid school name"
              solo
              flat
              class="input-default mt-1"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="district"
              >
                School District
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-0 pt-sm-3"
          >
            <v-autocomplete
              id="district"
              v-model="school.schoolDistrictId"
              class="input-default"
              :loading="districtListLoader"
              :items="districtList"
              :hide-details="!$v.school.schoolDistrictId.$error"
              :error-messages="$v.school.schoolDistrictId.$error ? 'Selecting a district is required' : null"
              item-text="name"
              item-value="id"
              menu-props="auto"
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="state"
              >
                State
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-0 pt-sm-3"
          >
            <v-autocomplete
              id="state"
              v-model="school.state"
              class="input-default"
              :items="stateList"
              item-text="label"
              item-value="value"
              menu-props="auto"
              :hide-details="!$v.school.state.$error"
              :error-messages="$v.school.state.$error ? 'Selecting a state is required' : null"
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="courseType"
              >
                SAT or ACT
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-0 pt-sm-3"
          >
            <v-select
              id="courseType"
              v-model="school.courseType"
              :items="courseTypes"
              item-text="label"
              item-value="value"
              class="input-default"
              :hide-details="!$v.school.courseType.$error"
              :error-messages="$v.school.courseType.$error ? 'Selecting a course type is required' : null"
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="seatsPurchased"
              >
                Seats Purchased
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-0 pt-sm-3"
          >
            <v-text-field
              id="seatsPurchased"
              v-model="school.seatsPurchased"
              :hide-details="!$v.school.seatsPurchased.$error"
              error-messages="Seats must consist only of numbers"
              solo
              flat
              class="input-default mt-1"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
              >
                Products Purchased
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
            class="pt-1 pt-sm-3 d-flex flex-wrap flex-grow-1"
          >
            <v-switch
              v-model="school.assessmentsAvailable"
              label="Assessments"
              color="#496e88"
              class="switch-form ml-1 mr-6 pt-1"
              inset
              hide-details
            />
            <v-switch
              v-model="school.activitiesAvailable"
              label="Course Activities"
              color="#496e88"
              class="switch-form ml-1 mt-1 mt-sm-0"
              inset
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label class="label-default">
                Assessment Settings
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
            class="pt-1 pt-sm-3"
          >
            <v-switch
              v-model="school.schoolAdminOnly"
              label="Assessment Management for School Administrators Only"
              color="#496e88"
              class="switch-form ml-1"
              inset
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="courseTemplate"
              >
                Course Template
              </label>
            </div>
          </v-col>
          <v-col
            v-if="!editMode"
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-0 pt-sm-3"
          >
            <v-autocomplete
              id="courseTemplate"
              v-model="school.heCourseTemplateIds[0]"
              :items="courseTemplateList"
              :loading="courseTemplateListLoader"
              item-text="name"
              item-value="he_course_template_id"
              class="input-default"
              clearable
              dense
              flat
              hide-details
            />
          </v-col>
          <v-col
            v-else
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="d-flex align-center flex-wrap"
          >
            <p class="form-input-text">
              {{ school.heCourseTemplateIds.length > 0 ? school.heCourseTemplateIds[0]?.heCourse[0]?.name : 'None' }}
            </p>
            <v-btn
              :loading="submittingForm"
              class="btn-default btn-default__secondary"
              @click="onChangeCourseDialog()"
            >
              Change Course Template
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="editMode">
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="districtCleverId"
              >
                Assessment Results Upload
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
          >
            <v-btn
              dark
              :loading="submittingForm"
              class="btn-default btn-default__primary"
              block
              @click="onUploadAssessmentResult()"
            >
              <v-img
                class="mr-2"
                contain
                :src="require('../assets/icons/add-1.png')"
                alt="icon-add"
                max-width="19"
                max-height="19"
              />
              Upload Assessment Results
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="canUpdateCleverId">
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="cleverSchoolId"
              >
                Clever School ID
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="d-flex pt-0 pt-sm-3"
          >
            <v-text-field
              id="cleverSchoolId"
              v-model="school.cleverId"
              hide-details
              solo
              flat
              class="input-default mt-1"
            />
            <v-btn
              v-if="canCleverSync"
              dark
              class="btn-default btn-default__secondary mt-1 ml-2"
              @click="showSisSyncReportsDialog()"
            >
              <v-img
                class="mr-2"
                contain
                :src="require('../assets/icons/usersearch2.png')"
                alt="icon-info"
                max-width="20"
                max-height="20"
              />
              Logs
            </v-btn>
            <v-btn
              v-if="canCleverSync"
              dark
              :loading="cleverSyncStarting"
              class="btn-default btn-default__primary mt-1 ml-2"
              @click="startCleverSync()"
            >
              <v-img
                class="mr-2"
                contain
                :src="require('../assets/icons/sync-white.png')"
                alt="icon-add"
                max-width="20"
                max-height="20"
              />
              Sync
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="canUpdateClasslinkId">
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="classlinkId"
              >
                ClassLink School ID
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="d-flex pt-0 pt-sm-3"
          >
            <v-text-field
              id="classlinkId"
              v-model="school.classlinkId"
              hide-details
              solo
              flat
              class="input-default mt-1"
            />
            <v-btn
              v-if="canClasslinkSync"
              dark
              class="btn-default btn-default__secondary mt-1 ml-2"
              @click="showSisSyncReportsDialog()"
            >
              <v-img
                class="mr-2"
                contain
                :src="require('../assets/icons/usersearch2.png')"
                alt="icon-info"
                max-width="20"
                max-height="20"
              />
              Logs
            </v-btn>
            <v-btn
              v-if="canClasslinkSync"
              dark
              :loading="classlinkSyncStarting"
              class="btn-default btn-default__primary mt-1 ml-2"
              @click="startClasslinkSync()"
            >
              <v-img
                class="mr-2"
                contain
                :src="require('../assets/icons/sync-white.png')"
                alt="icon-add"
                max-width="20"
                max-height="20"
              />
              Sync
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row
        dense
        justify="end"
        class="mt-6"
      >
        <v-col
          v-if="!editMode"
          cols="12"
          sm="auto"
        >
          <v-btn
            :loading="submittingForm"
            class="btn-default btn-default__secondary"
            block
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col
          v-if="editMode"
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__secondary"
            block
            @click="openDeleteDialog"
          >
            <v-img
              class="mr-2"
              contain
              :src="require(`@/assets/icons/trash-1.png`)"
              max-width="18"
              max-height="18"
              alt="icon-settings"
            />
            Delete School
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            v-if="editMode"
            dark
            :loading="submittingForm"
            class="btn-default btn-default__primary"
            block
            @click="onSubmitForm()"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('../assets/icons/diskette.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            Save Settings
          </v-btn>
          <v-btn
            v-else
            dark
            :loading="submittingForm"
            class="btn-default btn-default__primary"
            block
            @click="onSubmitForm()"
          >
            Create School
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="$v.$error"
        dense
        justify="end"
      >
        <v-col cols="auto">
          <h6 class="color-danger">
            Please check your inputs
          </h6>
        </v-col>
      </v-row>
    </v-container>

    <dialog-course-template-change
      v-if="editMode"
      :active="dialogCourseChange"
      :course-template-prop="school.heCourseTemplateIds[0]?.heCourse[0]"
      :course-template-list="courseTemplateList"
      @closeDialog="onCloseDialogCourseChange()"
      @confirmChange="onConfirmCourseChange(...arguments)"
    />

    <dialog-assessment-result-upload
      :active="dialogAssessmentResultUpload"
      :course-type-prop="school.courseType"
      :school-id="selectedSchool.id"
      @closeDialog="onCloseDialogAssessmentResultUpload()"
    />

    <dialog-delete-confirmation
      :active="showDeleteDialog"
      :deletion-loader-prop="deletionLoader"
      @confirmDeletion="onDeleteConfirm"
    />
    <dialog-update-confirmation
      :active="dialogUpdateConfirmationActive"
      :message="updateConfirmationMessage"
      @closeDialog="closeUpdateConfirmationDialog"
    />
    <dialog-sis-sync-meta-reports
      :active="dialogSisSyncMetaReportsActive"
      @closeDialog="closeSisSyncReportsDialog"
    />
  </div>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import DialogCourseTemplateChange from "@/components/shared/DialogCourseTemplateChange.vue";
import DialogAssessmentResultUpload from "@/components/shared/DialogAssessmentResultUpload.vue";
import {AdminDistrictApi, AdminSchoolApi} from "@/api";
import usStates from "@/assets/states.json";
import Axios from "axios";
import {required, numeric} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate/src";
import functions from "@/api/shared/functions";
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogSisSyncMetaReports from "@/components/shared/DialogSisSyncMetaReports.vue";
import DialogUpdateConfirmation from "@/components/shared/DialogUpdateConfirmation.vue";

export default {
  name: "CreateSchool",
  components: {
    DialogAssessmentResultUpload,
    DialogCourseTemplateChange,
    DialogDeleteConfirmation,
    DialogSisSyncMetaReports,
    DialogUpdateConfirmation,
    AppHeader
  },
  mixins: [validationMixin],
  props: {
    editMode: Boolean,
    selectedSchool: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      submittingForm: false,
      dialogCourseChange: false,
      districtListLoader: false,
      courseTemplateListLoader: false,
      dialogAssessmentResultUpload: false,
      cleverSyncStarting: false,
      classlinkSyncStarting: false,
      showDeleteDialog: false,
      deletionLoader: false,
      districtList: [],
      courseTemplateList: [],
      stateList: usStates,
      courseTypes: [
        {
          "label": "ACT",
          "value": "ACT"
        },
        {
          "label": "SAT",
          "value": "SAT"
        },
        {
          "label": "SAT & ACT",
          "value": "ACT_SAT"
        },
      ],
      school: {
        name: '',
        state: 'AL',
        courseType: 'SAT',
        seatsPurchased: null,
        assessmentsAvailable: false,
        activitiesAvailable: false,
        schoolAdminOnly: false,
        heCourseTemplateIds: [],
        cleverId: '',
        classlinkId: '',
      },
      dialogSisSyncMetaReportsActive: false,
      dialogUpdateConfirmationActive: false,
      updateConfirmationMessage: '',
      preUpdateChecked: false,
      updateConfirmed: false,
      updateCache: {
        sis: null
      }
    }
  },

  validations: {
    school: {
      name: {required},
      state: {required},
      courseType: {required},
      schoolDistrictId: {required},
      seatsPurchased: {numeric},
    }
  },

  computed: {

    canCleverSync() {
      return this.editMode && !!this.school.cleverId
    },

    canClasslinkSync() {
      return this.editMode && !!this.school.classlinkId
    },

    canUpdateCleverId() {
      return this.editMode && !!this.selectedSchool.schoolDistrict.cleverId
    },

    canUpdateClasslinkId() {
      return this.editMode && !!this.selectedSchool.schoolDistrict.classlinkId
    }

  },

  mounted() {
    if (Object.keys(this.selectedSchool).length > 0) {
      this.setSchool()
    }
    this.getDistrictList()
    this.getCourseTemplateList()
  },

  methods: {

    showUpdateConfirmationDialog(message = '') {
      this.updateConfirmationMessage = message
      this.dialogUpdateConfirmationActive = true
    },

    closeUpdateConfirmationDialog(allow) {
      this.dialogUpdateConfirmationActive = false
      this.updateConfirmed = allow
      this.onSubmitForm()
    },

    showSisSyncReportsDialog() {
      this.dialogSisSyncMetaReportsActive = true
    },

    closeSisSyncReportsDialog() {
      this.dialogSisSyncMetaReportsActive = false
    },

    async startCleverSync() {
      if (this.school.cleverId) {
        this.cleverSyncStarting = true
        let res = await AdminSchoolApi.cleverSync(this.selectedSchool.id)
        if (res.status == 'failed') {
          this.addNotification('error', res.message)
        } else {
          this.addNotification('success', res.message)
        }
        this.cleverSyncStarting = false
      }
    },

    async startClasslinkSync() {
      if (this.school.classlinkId) {
        this.classlinkSyncStarting = true
        let res = await AdminSchoolApi.classlinkSync(this.selectedSchool.id)
        if (res.status == 'failed') {
          this.addNotification('error', res.message)
        } else {
          this.addNotification('success', res.message)
        }
        this.classlinkSyncStarting = false
      }
    },

    setSchool() {
      this.school.name = this.selectedSchool.name
      this.school.state = this.selectedSchool.state
      this.school.courseType = this.selectedSchool.courseType
      this.school.seatsPurchased = this.selectedSchool.seatsPurchased
      this.school.assessmentsAvailable = this.selectedSchool.assessmentsAvailable
      this.school.activitiesAvailable = this.selectedSchool.activitiesAvailable
      this.school.schoolAdminOnly = this.selectedSchool.schoolAdminOnly
      this.school.heCourseTemplateIds = this.selectedSchool.heCourseTemplates
      this.school.cleverId = this.selectedSchool.cleverId
      this.school.classlinkId = this.selectedSchool.classlinkId
      this.school.schoolDistrictId = this.selectedSchool.schoolDistrictId
    },

    async getDistrictList() {
      try {
        this.districtListLoader = true
        const response = await AdminDistrictApi.selectList()
        this.districtList = response.districts
        if (Object.keys(this.selectedSchool).length === 0) {
          this.school.schoolDistrictId = this.districtList[0]?.id
        }
      } catch {
        this.addNotification('error', 'Failed to load districts.')
      } finally {
        this.districtListLoader = false
      }
    },

    async getCourseTemplateList() {
      try {
        this.courseTemplateListLoader = true
        const response = await Axios.get('/api/admin/course_activities/templates')
        this.courseTemplateList = response.data?.courseTemplates
      } catch {
        this.addNotification('error', 'Failed to load course templates.')
      } finally {
        this.courseTemplateListLoader = false
      }
    },

    preUpdateCheck() {
      this.preUpdateChecked = true

      let refClasslinkId = (this.updateCache.sis == null)
        ? this.selectedSchool.classlinkId : this.updateCache.sis.classlinkId
      let refCleverId = (this.updateCache.sis == null)
        ? this.selectedSchool.cleverId : this.updateCache.sis.cleverId

      if (this.school.classlinkId == refClasslinkId
        && this.school.cleverId == refCleverId) {
        return false
      } else {
        this.showUpdateConfirmationDialog('You are updating Clever/ClassLink IDs. Be aware that this will affect school roster syncing. Are you sure you want to continue?')
        return true
      }
    },

    cacheSisUpdates() {
      this.updateCache.sis = {
        cleverId: this.school.cleverId,
        classlinkId: this.school.classlinkId,
      }
    },

    async onSubmitForm() {
      try {
        this.submittingForm = true
        this.$v.$reset()
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        if (!this.editMode) {
          await AdminSchoolApi.create({school: this.school})
          this.addNotification('success', 'New school created')
          this.$router.push({name: 'Schools'})
        } else {
          let confirmingUpdate = false
          let allowUpdate = false

          if (!this.preUpdateChecked) {
            confirmingUpdate = this.preUpdateCheck()

            if (confirmingUpdate) {
              return
            } else {
              allowUpdate = true
            }
          } else {
            if (!this.updateConfirmed) {
              this.addNotification('warning', 'Update cancelled')
              allowUpdate = false
            } else {
              allowUpdate = true
            }
          }

          this.preUpdateChecked = false

          if (allowUpdate) {
            const dataToSend = functions.deepCopySync(this.school)
            dataToSend.heCourseTemplateIds[0] = this.school.heCourseTemplateIds[0]?.id
            await AdminSchoolApi.update(this.selectedSchool.id, dataToSend)
            this.addNotification('success', 'School settings updated')
            this.cacheSisUpdates()
          }
        }

      } catch {
        this.addNotification('error', 'Failed to create school. Please try again.')
      } finally {
        this.submittingForm = false
      }
    },

    onChangeCourseDialog() {
      this.dialogCourseChange = true
    },

    onConfirmCourseChange(courseTemplateProp) {
      if (courseTemplateProp === null) {
        courseTemplateProp = {}
      }
      if (this.school.heCourseTemplateIds[0]?.id !== courseTemplateProp?.id) {
        if (this.school.heCourseTemplateIds.length > 0) {
          this.school.heCourseTemplateIds[0].heCourse[0] = courseTemplateProp
          this.school.heCourseTemplateIds[0].id = courseTemplateProp.id
        } else {
          this.school.heCourseTemplateIds.push(
              {
                heCourse: [
                  courseTemplateProp
                ],
                id: courseTemplateProp.id
              },
          )
        }
      }
      this.dialogCourseChange = false
    },

    onUploadAssessmentResult() {
      this.dialogAssessmentResultUpload = true
    },

    onCloseDialogAssessmentResultUpload() {
      this.dialogAssessmentResultUpload = false
    },

    onCloseDialogCourseChange() {
      this.dialogCourseChange = false
    },

    openDeleteDialog() {
      this.showDeleteDialog = false
      this.$nextTick(() => {
        this.showDeleteDialog = true
      })
    },

    async onDeleteConfirm() {
      try {
        this.deletionLoader = true
        await AdminSchoolApi.delete(this.selectedSchool.id);
        this.addNotification('success', 'School successfully deleted');
        this.$router.push({name: 'Schools'});
      } catch {
        this.addNotification('error', 'Failed to delete school. Please try again.');
      } finally {
        this.deletionLoader = false
      }
    },
  }
}
</script>

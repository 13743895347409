import DistrictSchools from "@/components/district_admin/DistrictSchools.vue";
import ExportGenerate from "@/components/district_admin/ExportGenerate.vue";

export default [
    {
        path: '/district-admin/schools',
        name: 'DistrictSchools',
        component: DistrictSchools,
        meta: {
            breadcrumb: [
                {text: 'Schools'}
            ],
            roles: ['District Admin']
        },
    },
    {
        path: '/district-admin/export',
        name: 'ExportGenerate',
        component: ExportGenerate,
        meta: {
            breadcrumb: [
                {text: 'Exports'}
            ],
            roles: ['District Admin']
        },
    },
]

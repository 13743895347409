<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="dashboard-header justify-center justify-sm-space-between">
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <staff-dashboard-boxes
                  title="Training Center"
                  description="Training & Webinars"
                  :to-new-window="true"
                  filename="icon-resource-center.png"
                  link="https://horizoneducation.com/training/live-online-training"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <staff-dashboard-boxes
                  title="Support Center"
                  description="Help Documentation"
                  :to-new-window="true"
                  filename="icon-support-center.png"
                  link="https://support.horizoneducation.com/"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <staff-dashboard-boxes
                  title="Contact Us"
                  description="Customer Support Team"
                  filename="icon-contact-us.png"
                  link="contact-us"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col
        cols="12"
        sm="auto"
      >
        <h3>{{ showActiveClasses ? 'My Active Classes' : 'My Archived Classes' }}</h3>
      </v-col>
      <v-col
        cols="12"
        sm="auto"
      >
        <v-btn
          v-if="showActiveClasses"
          class="btn-default btn-default__secondary"
          title="Open Archived Classes"
          block
          @click="onClassesStatusChange(false)"
        >
          <v-img
            class="mr-2"
            contain
            :src="require('@/assets/icons/arrows-swap.png')"
            alt="icon-swap"
            max-width="15"
            max-height="15"
          />
          <span>
            Archived Classes
          </span>
        </v-btn>
        <v-btn
          v-else
          class="btn-default btn-default__secondary"
          title="Open Active Classes"
          block
          @click="onClassesStatusChange(true)"
        >
          <v-img
            class="mr-2"
            contain
            :src="require('@/assets/icons/arrows-swap.png')"
            alt="icon-swap"
            max-width="15"
            max-height="15"
          />
          <span>
            Active Classes
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="auto"
        class="d-flex flex-grow-1"
      >
        <v-text-field
          v-model="tableSearch"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          class="input-default "
          clearable
          hide-details
          solo
          flat
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :height="getTableHeight()"
          item-key="id"
          :server-items-length="totalClasses"
          :options.sync="tableOptions"
          :headers="headers"
          :headers-length="headers.length + 1"
          :items="classesList"
          :loading="tableLoading ? '#3d5d74' : false"
          class="table-default table-default__select table-default__header-fixed table-default__row-height-large"
          fixed-header
          :footer-props="{
            showCurrentPage: true,
            itemsPerPageOptions:[itemsPerPage],
            disableItemsPerPage: true,
          }"
        >
          <template #[`footer.page-text`]="items">
            <span class="font-weight-700">{{ items.pageStart }}</span> to
            <span class="font-weight-700">{{ items.pageStop }}</span> of
            <span class="font-weight-700">{{ items.itemsLength }}</span>
          </template>

          <template
            v-if="!isMobile"
            #[`item.displayName`]="{ item }"
          >
            <td>
              <div class="mb-2">
                {{ item.displayName }}
              </div>
              <div>
                <v-chip
                  class="class-chip"
                  :color="roleColor(item.role)"
                >
                  <span class="chip-text">
                    {{ item.role }}
                  </span>
                </v-chip>
                <v-chip
                  class="class-chip"
                  color="#FEE5B4"
                >
                  <span class="chip-text">
                    {{ item.schoolYear?.short_name }}
                  </span>
                </v-chip>
                <v-chip
                  class="class-chip"
                  color="#FEC1B4"
                >
                  <span class="chip-text">
                    {{ item.term?.name }}
                  </span>
                </v-chip>
              </div>
            </td>
          </template>

          <template
            v-if="!isMobile"
            #[`item.studentCount`]="{ item }"
          >
            <td>
              <span class="font-weight-700 color-orange">{{ item.studentCount }}</span> Students
            </td>
          </template>

          <template
            v-if="!isMobile"
            #[`item.actions`]="{ item }"
          >
            <td>
              <v-row dense>
                <v-col class="table-btn-container">
                  <v-btn
                    class="btn-table btn-table__text"
                    outlined
                    @click="onClassStudents(item)"
                  >
                    Students
                  </v-btn>
                  <v-btn
                    class="btn-table btn-table__text "
                    outlined
                    @click="onClassAssessments(item)"
                  >
                    Assessments
                  </v-btn>
                  <v-btn
                    class="btn-table btn-table__text "
                    outlined
                    @click="onClassCourseActivities(item)"
                  >
                    Activities
                  </v-btn>
                  <v-btn
                    class="btn-table btn-table__text "
                    outlined
                    @click="onClassSettings(item)"
                  >
                    Settings
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </template>

          <template
            v-if="isMobile"
            #item="{ item }"
          >
            <tr>
              <td>
                <ul class="flex-content">
                  <li
                    class="flex-item"
                    data-label="Class Name"
                  >
                    <div class="mb-2">
                      {{ item.displayName }}
                    </div>
                    <div>
                      <v-chip
                        class="class-chip"
                        :color="roleColor(item.role)"
                      >
                        <span class="chip-text">
                          {{ item.role }}
                        </span>
                      </v-chip>
                      <v-chip
                        class="class-chip"
                        color="#FEE5B4"
                      >
                        <span class="chip-text">
                          {{ item.schoolYear?.short_name }}
                        </span>
                      </v-chip>
                      <v-chip
                        class="class-chip"
                        color="#FEC1B4"
                      >
                        <span class="chip-text">
                          {{ item.term?.name }}
                        </span>
                      </v-chip>
                    </div>
                  </li>
                  <li
                    class="flex-item"
                    data-label="Students Enrolled"
                  >
                    <span class="font-weight-700 color-orange">{{ item.studentCount }}</span> Students
                  </li>
                  <v-col
                    cols="12"
                    class="table-btn-container flex-wrap"
                  >
                    <v-btn
                      class="btn-table btn-table__text"
                      outlined
                      @click="onClassStudents(item)"
                    >
                      Students
                    </v-btn>
                    <v-btn
                      class="btn-table btn-table__text "
                      outlined
                      @click="onClassAssessments(item)"
                    >
                      Assessments
                    </v-btn>
                    <v-btn
                      class="btn-table btn-table__text"
                      outlined
                      @click="onClassCourseActivities(item)"
                    >
                      Activities
                    </v-btn>
                    <v-btn
                      class="btn-table btn-table__text"
                      outlined
                      @click="onClassSettings(item)"
                    >
                      Settings
                    </v-btn>
                  </v-col>
                </ul>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
const {mapState} = createNamespacedHelpers('app_config')
import debounce from 'lodash/debounce'
import {StaffSectionApi} from "@/api";
import StaffDashboardBoxes from "@/components/staff/widgets/StaffDashboardBoxes.vue";


export default {
  components: {StaffDashboardBoxes},

  data() {
    return {
      schoolName: "",
      showActiveClasses: true,
      tableLoading: false,
      classesList: [],
      totalClasses: 0,
      itemsPerPage: 25,
      tableSearch: '',
      pageLoadedOnce: false,
      onTableSearch: false,
      tableOptions: {},
      tableHeight: 'calc(90vh - 390px)',

      headers: [
        {
          text: 'Class Name',
          value: 'displayName',
          align: 'left',
          sortable: true,
          sortBy: 'displayName',
        },
        {
          text: 'Enrollment',
          value: 'studentCount',
          align: 'left',
          sortable: false,
          width: 150
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 430
        }
      ],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),

  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        // Set page to 1 before searching
        this.resetPage()
        this.onTableSearch = true
        this.loadData()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.loadData()
        }
      },
      deep: true,
    },

  },

  mounted() {
    let user = this.user()
    if (user != null) {
      this.schoolName = user.school.name
      this.$route.params.school = this.schoolName
      this.$router.push({name: "ResetParamHelper",})
      this.$router.push({
        name: "StaffHome",
        params: {school: this.schoolName}
      })
    }
    this.loadData()
  },

  methods: {
    ...mapGetters(["user"]),

    async loadData() {
      try {
        this.tableLoading = true
        const {sortBy, sortDesc, page} = this.tableOptions
        let order = null
        let sort = 'displayName'
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        if (sortBy[0]) {
          sort = sortBy[0]
          order = sortDesc[0] ? 'desc' : 'asc'
        }
        const response = await StaffSectionApi.getAssignedSections(page, this.itemsPerPage, sort, order, searchText, !this.showActiveClasses)
        this.classesList = response.sections
        this.totalClasses = response?.meta?.totalCount
      } catch {
        this.addNotification('error', 'Failed to load class list')
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    onClassesStatusChange(status) {
      this.resetPage()
      this.showActiveClasses = status
      this.loadData()
    },

    roleColor(role) {
      return role === 'Primary Teacher' ? "#d7e2eb" : "#d7ebe2"
    },

    onClassSettings(section) {
      this.$router.push({name: "StaffClassSettings", params: {sectionId: section.id}})
    },

    onClassStudents(section) {
      this.$router.push({name: "StaffClassStudents", params: {sectionId: section.id}})
    },

    onClassAssessments(section) {
      this.$router.push({name: "StaffClassAssessments", params: {sectionId: section.id}})
    },

    onClassCourseActivities(section) {
      this.$router.push({
        name: "StaffCourseActivitiesCourse",
        params: {
          sectionId: section.id,
          courseTemplate: section.heCourseTemplateId,
        }
      })
    },

    resetPage() {
      this.tableOptions.page = 1
    },

    getTableHeight() {
      if (this.classesList.length < 5) return undefined

      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },
  }
}
</script>

<style scoped lang="scss">

.dashboard-header {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  justify-content: space-between;
  padding: 8px;
  background: linear-gradient(#eaf4ff, #dbe6f2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2) !important;
  border-radius: 12px;
}

.table-btn-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

// TODO The below code should be removed when branch with the general chip css gets merged with main
.class-chip {
  height: 24px;
  margin-bottom: 2px;
  margin-right: 10px;
}

.chip-text {
  font-size: 12px;
  color: #333333;
}

</style>
import { render, staticRenderFns } from "./DialogLearnosityPreview.vue?vue&type=template&id=80eba5aa&scoped=true&"
import script from "./DialogLearnosityPreview.vue?vue&type=script&lang=js&"
export * from "./DialogLearnosityPreview.vue?vue&type=script&lang=js&"
import style0 from "./DialogLearnosityPreview.vue?vue&type=style&index=0&id=80eba5aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80eba5aa",
  null
  
)

export default component.exports
<template>
  <div>
    <v-container
      :class="nested? 'pa-0' : null"
      fluid
    >
      <app-header
        :is-secondary="nested"
        title="My Classes"
        :from-dashboard="nested"
      />

      <div 
        v-if="loadingSchoolList"
      >
        <v-skeleton-loader
          max-width="700"
          tile
          type="list-item-two-line"
        />
        <v-skeleton-loader
          max-width="700"
          tile
          type="list-item-two-line"
        />      
      </div>
      <div 
        v-else
      >
        <div 
          v-if="sectionsAvailable"
        >
          <StaffClassListItem
            v-for="(section, index) in storedSections"
            :key="section.id"
            :index="index"
            :section="section"
            @assessmentsClicked="goToAssessments(section)"
            @settingsClicked="goToSettings(section)"
            @courseActivitiesClicked="goToCourseActivities(section)"
          />
        </div>
        <div 
          v-else
        >
          <v-img
            class="mr-2"
            contain
            :src="require('@/assets/img/staff-no-classes.jpeg')"
            max-width="300px"
          />
          <v-chip 
            class="ml-1 mr-3" 
            color="#d7ebe2"
          >
            You are not assigned as a teacher for any classes yet
          </v-chip>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import StaffClassListItem from './widgets/StaffClassListItem'
import AppHeader from "@/components/shared/AppHeader";

import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('staffSections')

export default {
  components: { 
    StaffClassListItem, 
    AppHeader 
  },

  props: {
    nested: { type: Boolean, default: false, required: false }
  },

  data() {
    return {
      loadingSchoolList: false
    }
  },
  
  computed: {
    sectionsAvailable() {
      return this.allSections().length > 0;
    },

    storedSections() {
      return this.allSections()
    }
  },
  
  methods: {
    ...mapState(['allSections']),

    goToSettings(section) {
      this.$router.push({ name: "StaffClassSettings", params: { sectionId: section.id } })
    },

    goToAssessments(section) {
      this.$router.push({ name: "StaffClassAssessments", params: { sectionId: section.id } })
    },

    goToCourseActivities(section) {
      localStorage.setItem('selectedTab', 0)
      this.$router.push({
        name: "StaffCourseActivitiesCourse",
        params: {
          sectionId: section.id,
          courseTemplate: section.heCourseTemplateId,
        }
      })
    }

  }
}
</script>
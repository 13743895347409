import router from '@/router'

function initialState () {
  return {
    isMobile: false,
    navigation: [],
    notifications: [],
    dashboard: '/'
  }
}

function dashboardByRole(user) {
  const dash_redirect = {
    'Horizon Admin': '/admin/schools',
    'District Admin': '/district-admin/schools',
    'Teacher Admin': '/staff',
    'Teacher': '/staff',
    'Student': '/student/dashboard',
    'Parent': '/parent/students'
  }

  if (user.role !== 'District Admin') {
    return dash_redirect[user.role]
  } else {
    if (Object.prototype.hasOwnProperty.call(user, "school")) {
      return '/staff'
    } else {
      return '/district-admin/schools'
    }
  }
}

function navigationByRole(payload) {
  let role = payload.role
  const showParentReportsNav = payload.showParentReportsNav
  let school = Object.prototype.hasOwnProperty.call(payload.data, "school") ? payload.data.school : null
  let schoolRoles = ['Student', 'Teacher', 'Teacher Admin']
  const nav = {
    'Horizon Admin': [
        {name: 'schools', title: 'Schools', icon: 'school2.png', route: '/admin/schools'},
        {name: 'districts', title: 'Districts', icon: 'district2.png', route: '/admin/districts'},
        {name: 'courses', title: 'Courses', icon: 'courseactivities2.png', route: '/admin/courses'},
        {name: 'userSearch', title: 'User Search', icon: 'usersearch2.png', route: '/admin/user-search'},
      ],
    'District Admin': [
        {name: 'schools', title: 'Schools', icon: 'school2.png', route: '/district-admin/schools'},
        {name: 'exports', title: 'Exports', icon: 'district2.png', route: '/district-admin/export'},
      ],
    'Teacher Admin': [
        {name: 'dashboard', title: 'Dashboard', icon: 'school2.png', route: '/staff/dashboard' },
        {name: 'assessments', title: 'Assessments', icon: 'courseactivities2.png', route: '/staff/assessments' },
        {name: 'reports', title: 'Reports', icon: 'barchart.png', route: '/staff/reports'},
        {name: 'courseActivities', title: 'Activities', icon: 'online-learning.png', route: '/staff/course_activities'},
      ],
    'Teacher': [
        {name: 'dashboard', title: 'Dashboard', icon: 'school2.png', route: '/staff/dashboard' },
        {name: 'assessments', title: 'Assessments', icon: 'courseactivities2.png', route: '/staff/assessments' },
        {name: 'reports', title: 'Reports', icon: 'barchart.png', route: '/staff/reports'},
        {name: 'courseActivities', title: 'Activities', icon: 'online-learning.png', route: '/staff/course_activities'},
      ],
    'Student': [
        {name: 'dashboard', title: 'Dashboard', icon: 'school2.png', route:'/student/dashboard'},
        {name: 'assessments', title: 'Assessments', icon: 'courseactivities2.png', route:'/student/assessments'},
        {name: 'courseActivities', title: 'Activities', icon: 'online-learning.png', route:'/student/course_activities'},
        {name: 'scoreReports', title: 'Score Reports', icon: 'barchart.png', route:'/student/score-reports'},
      ],
    'Parent': [
          {name: 'ParentDashboard', title: 'Students', icon: 'school2.png', route:'/parent/students'},
      ],
  }

  if (role === 'Principal' || role === 'Superintendent') {
    role = 'Administrator'
  }

  // check role Students/Teachers/Admins
  let navigations = nav[role]

  if (role === 'District Admin') {
    if (school) {
      navigations = [
        {name: 'dashboard', title: 'Dashboard', icon: 'school2.png', route: '/staff/dashboard' },
        {name: 'assessments', title: 'Assessments', icon: 'courseactivities2.png', route: '/staff/assessments' },
        {name: 'reports', title: 'Reports', icon: 'barchart.png', route: '/staff/reports'},
        {name: 'courseActivities', title: 'Activities', icon: 'online-learning.png', route: '/staff/course_activities'},
      ]
    }
  }

  if (role === 'Parent') {
    if (showParentReportsNav) {
      const studentId = router.currentRoute.params.studentId
      navigations = [
        {name: 'dashboard', title: 'Score Reports', icon: 'barchart.png', route: `/parent/${studentId}/score-reports` },
        {name: '', title: 'View Students', icon: 'district2.png', route: '/parent/students' },
      ]
    }
  }



  if (schoolRoles.includes(role)) {
    if (!school.activitiesAvailable) {
      navigations = nav[role].filter( item => item.name !== 'courseActivities')
    }
  }

  return navigations
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isMobile: state => state.isMobile,
    navigation: state => state.navigation,
    dashboard: state => state.dashboard
  },
  mutations: {
    updateIsMobile: (state, payload) => {
      state.isMobile = payload
    },
    updateNavigationRole: (state, payload) => {
      state.navigation = navigationByRole(payload)
    },
    updateDashboard: (state, payload) => {
      state.dashboard = dashboardByRole(payload)
    },
    clearNavigation: (state) => {
      state.navigation = []
    },

    pushNotification: (state, notification) => {
        state.notifications.push({
            ...notification,
            id: (Math.random().toString(36) + Date.now().toString(36)).slice(2)
        })
    },

    removeNotification: (state, notificationToRemove) => {
        state.notifications = state.notifications.filter(notification => {
            return notification.id.toString() !== notificationToRemove.id.toString();
        })
    }

  },
  actions: {
    showMobile: ({ commit }) => {
      commit('updateIsMobile', true)
    },
    showWide: ({ commit }) => {
      commit('updateIsMobile', false)
    },

    addNotification: ({ commit }, notification) => {
      commit('pushNotification', notification);
    },

    removeNotification: ({ commit }, notification) => {
      commit('removeNotification', notification);
    },

    updateMenu({commit}, payload) {
      commit('updateNavigationRole', payload)
    }

  }
}

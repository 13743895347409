<template>
  <div>
    <v-container fluid>
      <v-row
        class="border-bottom pb-3 mb-4"
        justify="space-between"
        align="center"
      >
        <v-col
          cols="12"
          class="pl-0"
        >
          <h3>{{ courseText }}</h3>
        </v-col>
      </v-row>
      <v-row
        v-if="courseActivity?.sections?.length > 0"
        class="border-bottom pb-3"
      >
        <div class="sections-container">
          <transition-group tag="div">
            <template v-if="courseActivity && courseActivity.sections">
              <course-section
                v-for="section in courseActivity.sections"
                :id="'section-' + (section ? section.id : '')"
                :key="'section' + (section ? section.id : '')"
                :editing="editMode"
                :section="section"
                :course-id="courseActivity ? courseActivity.id : null"
                :class-section="classSection"
                @section-update="loadCourse()"
              />
            </template>
          </transition-group>
        </div>
      </v-row>
      <v-row
        justify="end"
        align="center"
      >
        <v-col
          cols="12"
          sm="auto"
          class="px-0"
        >
          <v-btn
            block
            dark
            class="btn-default btn-default__secondary"
            @click="backToCourses()"
          >
            Back
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapState: mapUserState} = createNamespacedHelpers('user')
import CourseSection from "./CourseSection.vue"
import {StaffCourseActivitiesApi, StaffSectionApi} from '@/api'

export default {
  name: "CourseActivity",
  components: {
    CourseSection
  },
  data() {
    return {
      courseActivity: {},
      classSection: {},
      courseText: '',
      course_id: null,
      section_id: null,
      editMode: false,
      primaryTeacher: "Primary Teacher",
      teacherAdmin: "Teacher Admin",
    }
  },
  computed: {
    ...mapUserState(["user"]),
  },
  created() {
    this.courseActivity = this.$route.query;
    this.course_id = this.$route.params.courseTemplate;
    this.section_id = this.$route.params.sectionId;
    this.loadCourse();
  },
  methods: {
    async loadCourse() {
      try {
        const response = await StaffCourseActivitiesApi.getCourseActivities(
            this.course_id, this.section_id)
        this.courseActivity = response.course_activity
        this.courseText = response.course_activity.course_name
        this.loadSection()
      } catch (error) {
        console.log(error)
      }
    },
    async loadSection() {
      const response = await StaffSectionApi.get(this.section_id)
      this.checkEditingRole(response.section)
      this.classSection = response.section
    },
    checkEditingRole(section) {
      const primaryTeacherIds = section.primaryTeachers.map(teacher => teacher.id)
      this.editMode = primaryTeacherIds.includes(this.user.userId) || this.user.role === this.teacherAdmin;
    },

    backToCourses() {
      this.$router.push({name: 'StaffCourseActivities'})
    },
  }
}
</script>

<style scoped lang="scss">
.border-bottom {
  border-bottom: 1px solid #eaecf0;
}

.sections-container {
  width: 100%
}

.course-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0px 15px 0px 15px;
  margin-top: 0px;
  padding: 0px 0px 20px 0px;
  flex-direction: column;

  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 700;
    line-height: 150%;
    text-align: left;
  }

}

.course-pane {
  border-color: #c7c7c7;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 10px 15px 15px;
}

</style>

export default class LessonAssignment {
  constructor () {
    this.id = null
    this.name = null
    this.memo = null
    this.instructional_time = null
    this.independent_practice_time = null
    this.learnosity_activity_banks = null
    this.support_materials = null
    this.he_course_section_id = null
    this.order = null
    this.start_at = null
    this.end_at = null

    this.initializeRules()
  }

  initializeRules() {
    this.rules = {
      start_at: [
        v => !!v || 'Start date is required'
      ],
      end_at: [
        v => {
          let condition = v == null || v == "" || ((v != null && v != "") && this.start_at && this.end_at > this.start_at)
          return condition || 'End date must be after start date'
        }
      ],
    }

  }

  get formattedStartAtDate() {
    if (this.start_at != null) {
      return this.start_at.toDateString()
    } else {
      return null
    }
  }
  
  get startAtDate() {
    if (this.start_at != null) {
      return this.start_at.toISOString().substr(0, 10)
    } else {
      return ""
    }
  }

  set startAtDate(value) {
    let prevDate = this.start_at
    const dateTemp = new Date(value)
    this.start_at = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )
    if (prevDate != null) {
      this.start_at.setHours(prevDate.getHours())
      this.start_at.setMinutes(prevDate.getMinutes())
    }
  }

  get startAtTime() {
    if (this.start_at != null) {
      return `${this.start_at.getHours().toString().padStart(2, '0')}:${this.start_at.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set startAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.start_at != null) {
        this.start_at = new Date(this.start_at.getFullYear(), this.start_at.getMonth(), this.start_at.getDate(), time.getHours(), time.getMinutes())
      } else {
        let today = new Date()
        this.start_at = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.getHours(), time.getMinutes())
      }
    }
  }

  get formattedEndAtDate() {
    if (this.end_at != null) {
      return this.end_at.toDateString()
    } else {
      return ""   
    }
  }

  set formattedEndAtDate(value) {
    this.end_at = value
  }

  get endAtDate() {
    if (this.end_at != null) {
      return this.end_at.toISOString().substr(0, 10)
    } else {
      return ""   
    }
  }

  set endAtDate(value) {
    if (value != null)  {
      let prevDate = this.end_at
      const dateTemp = new Date(value)
      this.end_at = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )
      if (prevDate != null) {
        this.end_at.setHours(prevDate.getHours())
        this.end_at.setMinutes(prevDate.getMinutes())
      }
    } else {
      this.end_at = null
    }
  }

  get endAtTime() {
    if (this.end_at != null) {
      return `${this.end_at.getHours().toString().padStart(2, '0')}:${this.end_at.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set endAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.end_at != null) {
        this.end_at = new Date(this.end_at.getFullYear(), this.end_at.getMonth(), this.end_at.getDate(), time.getHours(), time.getMinutes())
      } else {
        this.end_at = new Date(this.start_at.getFullYear(), this.start_at.getMonth(), this.start_at.getDate(), time.getHours(), time.getMinutes())
      }
    }
  }
}
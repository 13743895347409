<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <!-- start Active Assignments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col
        align-self="end"
      >
        <h3>Active Assignments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(activeAssignments)"
      :headers="headers"
      :headers-length="activeAssignments.length + 1"
      :items="activeAssignments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>
      <template
        v-if="editMode"
        #[`item.action`]="{ item }"
      >
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Active Assignments -->

    <!-- start upcoming Assignments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col
        align-self="end"
      >
        <h3>Upcoming Assignments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(upcomingAssignments)"
      :headers="headers"
      :headers-length="upcomingAssignments.length + 1"
      :items="upcomingAssignments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>
      <template
        v-if="editMode"
        #[`item.action`]="{ item }"
      >
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end upcoming Assignments -->

    <!-- start past Assignments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col
        align-self="end"
      >
        <h3>Past Assignments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(upcomingAssignments)"
      :headers="headers"
      :headers-length="pastAssignments.length + 1"
      :items="pastAssignments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>
      <template
        v-if="editMode"
        #[`item.action`]="{ item }"
      >
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end past assignments -->

    <dialog-assign-lesson
      :title="dialogAssignLessonTitle"
      :active="dialogAssignLesson"
      :lesson="lesson"
      :type="false"
      @assignLesson="updateLessonTiming($event)"
      @closeDialog="closeDialogAssignLesson()"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteLessonAssignmentConfirmation"
      :deletion-loader-prop="deletionLoader"
      :message="deleteMessage"
      @closeDialog="closeDialogDeleteConfirmation()"
      @confirmDeletion="confirmDeleteAssignLesson()"
    />
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapState: mapUserState} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')

import {StaffAssignmentSchedulesApi, StaffCourseActivitiesApi} from '@/api'
import DialogAssignLesson from '@/components/staff/course/shared/DialogAssignLesson.vue'
import DialogDeleteConfirmation from '@/components/shared/DialogDeleteConfirmation.vue'

export default {
  name: "StaffCourseActivitiesAssignments",

  components: {
    DialogAssignLesson,
    DialogDeleteConfirmation
  },

  data() {
    return {
      tableLoading: true,
      course_id: null,
      section_id: null,
      courseActivity: {},
      headers: [],
      lesson: {},
      lessonsData: [],
      dialogAssignLesson: false,
      dialogAssignLessonTitle: '',
      dialogDeleteLessonAssignmentConfirmation: false,
      deletionLoader: false,
      deleteMessage: 'Are you sure you want to Delete the Lesson Assignment?',
      editMode: false,
      primaryTeacher: "Primary Teacher",
      teacherAdmin: "Teacher Admin",
      tableHeight: 'calc(90vh - 540px)',
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
    ...mapUserState(["user"]),

    activeAssignments() {
      const currentDateTime = this.getCurrentDateTime()
      return this.lessonsData.filter(lesson => {
        if (lesson.assignment_schedule && lesson.assignment_schedule.start_at) {
          return (
              lesson.assignment_schedule.start_at <= currentDateTime &&
              (lesson.assignment_schedule.end_at === null || lesson.assignment_schedule.end_at >= currentDateTime)
          )
        }
        return false
      })
    },

    upcomingAssignments() {
      const currentDateTime = this.getCurrentDateTime()
      return this.lessonsData.filter(lesson => {
        if (lesson.assignment_schedule && lesson.assignment_schedule.start_at) {
          return lesson.assignment_schedule.start_at > currentDateTime
        }
        return false
      })
    },

    pastAssignments() {
      const currentDateTime = this.getCurrentDateTime()
      return this.lessonsData.filter(lesson => {
        if (lesson.assignment_schedule && lesson.assignment_schedule.end_at) {
          return lesson.assignment_schedule.end_at < currentDateTime
        }
        return false
      })
    },
  },

  mounted() {
    this.course_id = this.$route.params.courseTemplate;
    this.section_id = this.$route.params.sectionId;
    this.setHeaders();
    this.loadCourse();
    this.loadSection();
  },

  methods: {
    ...mapSectionGetters(["getAllSections"]),

    async loadCourse() {
      try {
        const response = await StaffCourseActivitiesApi.getCourseActivities(this.course_id, this.section_id)
        this.courseActivity = response.course_activity
        this.extractLessonsData(this.courseActivity)
        this.tableLoading = false
      } catch {
        this.addNotification('error', 'Failed to load assignments.')
      }
    },

    setHeaders() {
      this.headers = [
        {
          text: 'Section Name',
          value: 'section_name',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Lesson Name',
          value: 'name',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Start Time',
          value: 'formatedStartAt',
          align: 'left',
          sortable: true,
        },
        {
          text: 'End Time',
          value: 'formatedEndAt',
          align: 'left',
          sortable: true,
        },
        {
          text: '',
          value: 'action',
          align: 'left',
          sortable: false,
          width: 180,
        }
      ]
    },

    extractLessonsData(course_activity) {
      this.lessonsData = []
      if (course_activity.sections && Array.isArray(course_activity.sections)
      ) {
        for (const section of course_activity.sections) {
          if (section.lessons && Array.isArray(section.lessons)) {
            const sectionName = section.name
            for (const lesson of section.lessons) {

              lesson.section_name = sectionName
              if (lesson.assignment_schedule) {
                lesson.formatedStartAt = this.formatDate(lesson.assignment_schedule.start_at)
                lesson.formatedEndAt = lesson.assignment_schedule.end_at ? this.formatDate(lesson.assignment_schedule.end_at) : ''
              }
            }
            this.lessonsData.push(...section.lessons)
          }
        }
      }
    },

    getCurrentDateTime() {
      return new Date().toISOString()
    },

    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }
      return new Date(dateString).toLocaleString(undefined, options)
    },

    openDialogAssignLesson() {
      this.dialogAssignLesson = true
    },

    closeDialogAssignLesson() {
      this.dialogAssignLesson = false
    },

    editAssignLesson(lesson) {
      this.lesson = lesson
      this.dialogAssignLessonTitle = 'Edit Lesson: ' + lesson.name
      this.openDialogAssignLesson()
    },

    openDialogDeleteConfirmation(lesson) {
      this.lesson = lesson
      this.dialogDeleteLessonAssignmentConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteLessonAssignmentConfirmation = true
      })
    },

    closeDialogDeleteConfirmation() {
      this.dialogDeleteLessonAssignmentConfirmation = false
    },

    confirmDeleteAssignLesson() {
      const timingData = {
        'start_at': null,
        'end_at': null,
      }
      this.deletionLoader = true
      this.updateLessonTiming(timingData)
    },

    async updateLessonTiming(data) {
      const timingData = {
        'start_at': data.start_at,
        'end_at': data.end_at,
        'section_id': this.section_id
      }
      try {
        await StaffAssignmentSchedulesApi.updateAssignmentSchedule(this.lesson.assignment_schedule.id, timingData)
        this.loadCourse()
        this.closeDialogDeleteConfirmation()
      } catch {
        this.addNotification('error', "Something went wrong. Please try again.")
      } finally {
        this.deletionLoader = false
      }
    },

    async loadSection() {
      const sectionParent = this.getAllSections().find(section => section.id == this.section_id)

      this.checkEditingRole(sectionParent.role)
    },

    checkEditingRole(role) {
      this.editMode = role === this.primaryTeacher || this.user.role === this.teacherAdmin;
    },

    getTableHeight(array) {
      if (array.length < 6) return undefined

      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },
  },
}
</script>
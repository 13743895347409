<template>
  <div>
    <v-container
      class="mt-1"
      fluid
    >
      <app-header title="Course Activities" />

      <v-row>
        <v-col>
          <v-skeleton-loader
            v-if="tableLoading"
            type="image"
            height="50"
          />
          <v-data-table
            :hide-default-header="true"
            :hide-default-footer="true"
            :items="sections"
            class="table-default table-default__courses"
            mobile-breakpoint="0"
            :items-per-page="500"
          >
            <template #item="{ item }">
              <tr>
                <td
                  class="text-left"
                >
                  <div class="sheet-main">
                    <div>
                      <h4 class="section-name">
                        {{ item.displayName }}
                      </h4>
                      <div>
                        <v-chip
                          v-if="item.role"
                          class="chip-small mr-2"
                          :color="roleColor(item.role)"
                        >
                          <span class="chip-text font-weight-400">
                            {{ item.role }}
                          </span>
                        </v-chip>
                        <span class="color-orange font-weight-600 font-size-14">{{ item.studentCount }}</span> <span class="text-students">Students Enrolled</span>
                      </div>
                    </div>

                    <div class="d-flex flex-wrap">
                      <v-btn
                        class="btn-default btn-default__secondary mt-1 mr-4"
                        outlined
                        @click="viewCourseActivitiesClass(item)"
                      >
                        <v-img
                          max-width="19"
                          max-height="19"
                          class="mr-2"
                          src="@/assets/icons/courseactivities_orange.png"
                        />
                        Course Activities
                      </v-btn>
                      <v-btn
                        class="btn-default btn-default__secondary mr-4 mt-1"
                        outlined
                        @click="onCourseAssignments(item)"
                      >
                        <v-img
                          max-width="19"
                          max-height="19"
                          class="mr-2"
                          src="@/assets/icons/approve.png"
                        />
                        Assignments
                      </v-btn>
                      <v-btn
                        class="btn-default btn-default__secondary mt-1"
                        outlined
                        @click="onCourseGradebook(item)"
                      >
                        <v-img
                          max-width="19"
                          max-height="19"
                          class="mr-2"
                          src="@/assets/icons/assessments_orange.png"
                        />
                        Gradebook
                      </v-btn>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
import AppHeader from "@/components/shared/AppHeader.vue";
import {StaffSectionApi} from '@/api'
import {Section} from '@/models'

export default {
  name: "StaffCourseActivities",
  components: {
    AppHeader
  },
  data() {
    return {
      tableLoading: true,
      sections: [],
    }
  },
  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    })
  },
  mounted() {
    this.loadCourses()
    localStorage.setItem('selectedTab', 0)
  },
  methods: {
    async loadCourses() {
      const response = await StaffSectionApi.list()
      this.sections = response.sections.map(section => new Section(section))

      this.tableLoading = false
    },
    viewCourseActivitiesClass(section) {
      this.$router.push({
        name: "StaffCourseActivitiesCourse",
        params: {
          sectionId: section.id,
          courseTemplate: section.heCourseTemplateId,
        }
      })
    },

    onCourseAssignments(section) {
      this.$router.push({
        name: "StaffCourseActivitiesAssignments",
        params: {
          sectionId: section.id,
          courseTemplate: section.heCourseTemplateId,
        }
      })
    },

    onCourseGradebook(section) {
      this.$router.push({
        name: "StaffCourseActivitiesGradebook",
        params: {
          sectionId: section.id,
          courseTemplate: section.heCourseTemplateId,
        }
      })
    },

    roleColor(role) {
      return role === 'Primary Teacher' ? '#d7e2eb' : '#d7ebe2'
    }
  }
}
</script>

<style scoped lang="scss">

.sheet-main {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100px;
}

.section-name {
  color: #2c495e;
  font-size: 20px;
  margin-bottom: 8px;
}

.text-students {
  font-size: 14px;
  color: #101828;
}

.v-data-footer {
  display: none;
}

.primary-teacher {
  background-color: #d7e2eb;
}

.secondary-teacher {
  background-color: #d7ebe2;
}

.enrolled_students {
  color: #f47e26;
}
</style>

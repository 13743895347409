import HorizonApi from '@/api/horizon_api'

const STAFF_COURSE_ACTIVITIES_PATH= `/api/staff/course_activities`

export default {
  list () {
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}`)
  },
  getTemplate(template_id) {
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/templates/${template_id}`)
  },
  getCourseActivities(course_id,section_id){
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/${section_id}`)
  },
  updateLessonTiming(course_id,lesson_id,data = {}){
    return HorizonApi.put(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/lesson/${lesson_id}`, {'lesson': data})
  },
  getGradebook(course_id,section_id){
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/gradebook/table_data/${section_id}`)
  },
  deleteResponse(course_id,section_id,response_id){
    return HorizonApi.delete(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/gradebook/${section_id}/response/${response_id}`)
  },
  exportGradebook(course_id,section_id){
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/gradebook/export/${section_id}`)
  },
  getItemAnalysisConf(data){
    let params = {
      params: {
        query: data.query
      }
    }
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${data.course_id}/lesson/item_analysis_report/${data.lesson_id}/${data.activity_id}/${data.section_id}`, params)
  },
}
<template>
  <div>
    <v-container fluid>
      <app-header title="Course Activities" />
      <div v-if="loadingLessons">
            <v-skeleton-loader max-width="100%" type="list-item"/>
            <v-skeleton-loader max-width="100%" type="list-item"/>
            <v-skeleton-loader max-width="100%" type="list-item"/>
      </div>
      <div v-else>
        <div v-if="!hasAssignedActivities">No Course Activities Assigned</div>
        <div v-else>
          <v-row class="activity-section assigned-section mt-1">
            <v-col>
              <div class="section-label">
                  Assigned Activities
              </div>
              <div v-if="loadingActivities">
                <v-skeleton-loader max-width="100%" type="list-item"/>
                <v-skeleton-loader max-width="100%" type="list-item"/>
                <v-skeleton-loader max-width="100%" type="list-item"/>
              </div>
              <div v-else>
                <div v-for="(lesson, index) in assignedLessons" :key="'lesson' + index" class="lesson-group-item item">
                  <course-lesson
                    :id="index + '-lesson-' + (lesson ? lesson.id : '')"
                    :lesson="lesson"
                  ></course-lesson>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-spacer class="activity-spacer" />
          <v-row class="activity-section completed-section mt-1">
            <v-col>
              <div class="section-label">
                  Completed Activities
              </div>
              <div v-if="loadingActivities">
                <v-skeleton-loader max-width="100%" type="list-item"/>
                <v-skeleton-loader max-width="100%" type="list-item"/>
                <v-skeleton-loader max-width="100%" type="list-item"/>
              </div>
              <div v-else>
                <div v-for="(lesson, index) in completedLessons" :key="'lesson' + index" class="lesson-group-item item">
                  <course-lesson
                    :id="index + '-lesson-' + (lesson ? lesson.id : '')"
                    :lesson="lesson"
                  ></course-lesson>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue"
import { StudentCourseActivityApi } from '@/api'
import CourseLesson from './course/CourseLesson.vue'

export default {
  components: {
    AppHeader,
    CourseLesson
  },
  data() {
    return {
      courseActivity: {},
      courseLessons: [],
      assignedLessons: [],
      completedLessons: [],
      lessonResponses: {},
      loadingActivities: true,
      loadingLessons: true,
      hasAssignedActivities: false,
    }
  },
  created() {
    let learnosityScript = document.createElement('script')
    learnosityScript.setAttribute('src', 'https://items.learnosity.com/')
    document.head.appendChild(learnosityScript)

    learnosityScript = document.createElement('script')
    learnosityScript.setAttribute('src', 'https://reports.learnosity.com/')
    document.head.appendChild(learnosityScript)

    this.loadCourseData()
  },
  methods: {
    async loadCourseData() {
      try{
        let res = await StudentCourseActivityApi.getCourseActivity()
        this.courseActivity = res.courseActivity
        this.courseLessons = res.courseActivity.sections.flatMap((s) => {
          const currentDate = new Date()
          let lessons = s.lessons.filter(e => {
            const schedule = e.assignmentSchedule ? e.assignmentSchedule : null
            const startAtDate = schedule && schedule.start_at ? new Date(schedule.start_at) : null
            
            return (
              startAtDate && startAtDate <= currentDate 
            )
          })
          lessons.forEach(l => l.sectionDetails = {id: s.id, name: s.name})
          return lessons
        })
        
        this.loadingLessons = false
        
        if (this.courseLessons.length > 0 ) {
          this.hasAssignedActivities = true
          await this.loadCourseResponses()
          this.parseLessons()
        }
      }catch(error){
        console.log(error)
      }
    },

    async loadCourseResponses() {
      let lesson_ids = this.courseLessons.map(e => e.id)
      let res = await StudentCourseActivityApi.getLearnosityResponses(lesson_ids)
      this.lessonResponses = res.responses
    },

    parseLessons() {
      this.courseLessons.forEach((e) => {
        let lesson_responses = this.getLessonResponses(e.id)
        let schedule = e.assignmentSchedule ? e.assignmentSchedule : null
        let endAtDate = schedule && schedule.end_at ? new Date(schedule.end_at) : null
        let toAssignLesson = endAtDate === null || endAtDate >= new Date() 

        if (lesson_responses) {
          e.lessonResponses = lesson_responses
          let nonNullKeys = Object.keys(e.learnosityActivityBanks).filter(key => e.learnosityActivityBanks[key] !== null)

          if (this.isComplete(lesson_responses.responses, nonNullKeys)) {
            e.isComplete = true
            this.completedLessons.push(e)
          } else if(toAssignLesson) {
            e.isComplete = false
            this.assignedLessons.push(e)
          }
        } else if(toAssignLesson) {
          this.assignedLessons.push(e)
        }
      })
      this.loadingActivities = false
    },

    isComplete(responses, activities) {
      if (Object.keys(responses).length == 0){ return false }

      let il_ok = true
      if (this.hasProperty(responses, 'independent_lesson')) {
        il_ok = responses.independent_lesson.status == 'Completed'
      } else if (activities.includes('independentLesson')) {
        il_ok = false
      }

      let ps_ok = true
      if (this.hasProperty(responses, 'practice_set')) {
        ps_ok = responses.practice_set.status == 'Completed'
      } else if (activities.includes('practiceSet')) {
        ps_ok = false
      }

      return il_ok && ps_ok
    },

    getLessonResponses(lesson_id) {
      if (Object.keys(this.lessonResponses).length > 0) {
        return this.lessonResponses.find(e => e.lesson_id == lesson_id)
      }
      return false
    }

  },
}

</script>

<style scoped lang="scss">
  @import '~@/styles/variables';

  .section-label {
    height: 40px;
    font-size: 24px !important;
    font-weight: 600;
  }

  .activity-spacer {
    height: 40px;
  }

</style>

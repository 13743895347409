import StudentDashboard from "@/components/student/StudentDashboard.vue";
import StudentAssessments from "@/components/student/StudentAssessments.vue";
import StudentCourseActivities from "@/components/student/StudentCourseActivities.vue";
import StudentCourseLearnosityActivity from "@/components/student/course/LearnosityActivity.vue";
import StudentScoreReports from "@/components/student/StudentScoreReports.vue";
import StudentExamView from "@/components/student/StudentExamView.vue";
import StudentReportIndividual from "@/components/student/reports/StudentReportIndividual.vue";
import StudentReportComposite from "@/components/student/reports/StudentReportComposite.vue";
import SessionDetailByItemReport from "@/components/student/reports/SessionDetailByItemReport.vue";


export default [
    {
        path: '/student/dashboard',
        name: 'StudentDashboard',
        component: StudentDashboard,
        meta: {
            breadcrumb: [
                {text: 'Dashboard'}
            ],
            roles: ['Student']
        },
    },
    {
        path: '/student/assessments',
        name: 'StudentAssessments',
        component: StudentAssessments,
        meta: {
            breadcrumb: [
                {text: 'Assessments'}
            ],
            roles: ['Student']
        },
    },
    {
        path: '/student/course_activities',
        name: 'StudentCourseActivities',
        component: StudentCourseActivities,
        meta: {
            roles: ['Student']
        },
    },
    {
        path: '/student/score-reports',
        name: 'StudentScoreReports',
        component: StudentScoreReports,
        meta: {
            breadcrumb: [
                {text: "Score Reports"}
            ],
            roles: ['Student']
        },
    },
    {
        path: '/student/assessments/test/:id',
        name: 'StudentExamView',
        component: StudentExamView,
        props: true,
        meta: {
            breadcrumb: [
                {
                    text: 'Assessments',
                    to: {name: 'StudentAssessments'}
                },
                {text: "Test"}
            ],
            roles: ['Student']
        },
    },
    {
        path: '/student/assessments/summary/:studentAssessmentId',
        name: 'StudentReportIndividual',
        component: StudentReportIndividual,
        props: true,
        meta: {
            breadcrumb: [
                {
                    text: 'Assessments',
                    to: {name: 'StudentAssessments'}
                },
                {
                    text: "Score Summary",
                },
            ],
            roles: ['Student']
        },
    },
    {
        path: '/student/assessments/summary/:assessmentId/session_detail_by_item',
        name: 'SessionDetailByItemReport',
        component: SessionDetailByItemReport,
        props: true,
        meta: {
            breadcrumb: [
                {
                    text: 'Assessments',
                    to: {name: 'StudentAssessments'}
                },
                {text: "View Questions and Correct Answers"}
            ],
            roles: ['Student']
        },
    },
    {
        path: '/student/score-reports/:assessmentTypeId/:assessmentSetId',
        name: 'StudentReportComposite',
        component: StudentReportComposite,
        props: route => ({ ...route.query, ...route.params }),
        meta: {
            breadcrumb: [
                {
                    text: "Score Reports",
                    to: {name: 'StudentScoreReports'}
                },
                {
                    text: 'Score Report',
                }
            ],
            roles: ['Student']
        },
    },
    {
        path: '/student/course_activities/learnosity/:activityId',
        name: 'StudentCourseLearnosityActivity',
        component: StudentCourseLearnosityActivity,
        meta: {
            breadcrumb: [
                {
                    text: 'Course Activities',
                    to: {name: 'StudentCourseActivities'}
                },
                {
                    text: 'Learnosity'
                }
            ],
            roles: ['Student']
        }
    }

]

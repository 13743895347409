<template>
  <v-container fluid class="pt-0 pb-0">
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col
        align-self="end"
      >
        <h3>Gradebook: {{ gradebookCourseName }}</h3>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          class="btn-default btn-default__secondary ml-3"
          outlined
          @click="gradebookExport"
        >
          <v-img 
            max-width="19"
            max-height="19"
            class="mr-2"
            src="@/assets/icons/cells.png"
          />
          Export Gradebook
        </v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="loadingTableData"
      type="image"
      height="50"
    />
    <gradebook v-else  :editMode="editMode" :courseId="course_id" :sectionId="section_id" :gradebookTableData="gradebookTableData" @table-update="getGradebook"/>
  </v-container>
</template>

<script>
import { createNamespacedHelpers} from "vuex";
const { mapState: mapUserState } = createNamespacedHelpers('user')
const { mapState } = createNamespacedHelpers('staffSections')
import { StaffCourseActivitiesApi } from '@/api'
import Gradebook from '@/components/shared/Gradebook.vue'

export default {
  components: {
    Gradebook
  },
  data () {
    return {
      courseActivity: {},
      gradebookCourseName: '',
      course_id: null,
      section_id: null,
      section: {},
      gradebookTableData: {},
      loadingTableData: true,
      editMode: false,
      primaryTeacher: "Primary Teacher",
      teacherAdmin: "Teacher Admin",
    }
  },
  computed: {
    ...mapUserState(["user"]),
  },
  created() {
    this.course_id = this.$route.params.courseTemplate;
    this.section_id = this.$route.params.sectionId;
    this.checkEditingRole();
    this.loadCourse();
    this.getGradebook();
  },
  methods: {
    ...mapState(['allSections']),
    async loadCourse(){
      try {
        const response = await StaffCourseActivitiesApi.getCourseActivities(
            this.course_id, this.section_id)
        this.courseActivity = response.course_activity
        this.gradebookCourseName = this.courseActivity.course_name
      } catch (error) {
        console.log(error)
      }
    },
    async getGradebook(){
      this.loadingTableData = true
      try {
        const response = await StaffCourseActivitiesApi.getGradebook(this.course_id, this.section_id)
        this.gradebookTableData = response.gradebook_table
        this.loadingTableData = false
      } catch (error) {
        console.log(error)
      }
    },
    async gradebookExport() {
      try {
        const response = await StaffCourseActivitiesApi.exportGradebook(this.course_id, this.section_id)
        const filename = this.gradebookCourseName + '_Gradebook_Export.csv'
        const data = response
        const blob = new Blob([data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error(error)
      }
    },
    checkEditingRole(){
      const section = this.allSections().find(section => section.id == this.section_id)
      this.editMode = section.role === this.primaryTeacher ? true : false
    },
  },
}
</script>

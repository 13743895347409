<template>
  <v-container fluid>
    <app-header :title="liveMonitoringName"/>
    <v-row
      v-if="difficulty != 'default'"
    >
      <v-col
        cols="3"
        sm="auto"
        class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
      >
        <div>
          <h3>
            {{ difficultyLabel }} :
          </h3>
        </div>
      </v-col>
      <v-col
        cols="3"
        sm="3"
        md="3"
        lg="3"
        class="pt-0 pt-sm-3"
      >
        <v-select
          v-model="selectedDifficulty"
          dense
          flat
          class="input-default"
          hide-details
          :items="difficulty == 'default' ? ['Default'] : ['Easy','Hard']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-text-field
        v-if="!reportLoading"
        v-model="search"
        placeholder="Search by Student Name"
        prepend-inner-icon="mdi-magnify"
        hide-details
        solo
        flat
        class="input-default input-default__table"
      />
    </v-row>
    <v-row class="my-5" id="learnosityContainer">
      <v-skeleton-loader
          v-if="reportLoading"
          type="table-thead, table-tbody"
          width="100%"
          height="300"
        />
      <div
        id="live-activitystatus-by-user-1"
        :class="learnosityVisibility"
      />
    </v-row>
    <v-row class="d-flex justify-end">
      <v-btn 
        v-if="hasPrevPart"
        class="btn-default btn-default__secondary mr-2"
        @click="page-=1">
        Previous
      </v-btn>

      <v-btn 
        v-if="hasNextPart"
        class="btn-default btn-default__secondary"
        @click="page+=1">
        Next
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import {createNamespacedHelpers} from "vuex";
import AppHeader from "@/components/shared/AppHeader.vue"
import debounce from "lodash/debounce"
import { StaffAssessmentApi } from '@/api'

const {mapState: mapUserState} = createNamespacedHelpers('user')

export default {
  components: {
    AppHeader
  },
  props: {
    sectionId: {
      type: [Number, String],
      required: false
    },
    assessmentSetId: {
      type: [Number, String],
      required: false
    },
    assessmentTypeId: {
      type: [Number, String],
      required: false
    },
    subjectName: {
      type: [Number, String],
      required: false
    },
    difficulty: {
      type: [Number, String],
      required: false
    },
  },
  data(){
    return {
      reportLoading: true,
      learnosityVisible: true,
      liveMonitoringName: 'Live Monitoring',
      learnosityReportName: "live-activitystatus-by-user-1",
      difficultyLabel: 'Part 2 Difficulty',
      selectedDifficulty: this.difficulty == 'default' ? 'Default' : 'Easy',
      pagination: {},
      page: 1,
      perPage: 50,
      search: null,
    }
  },
  watch: {
    selectedDifficulty(){
      this.loadReport(this.page)
    },
    page() {
      this.loadReport(this.page)
    },
    search: {
      handler: debounce(function () {
        this.loadReport(this.page)
      }, 500)
    },
  },
  computed: {
    ...mapUserState(["user"]),
    courseType() {
      return this.user.school.courseType
    },
    learnosityVisibility() {
      return {
        visibility: this.learnosityVisible ? 'hidden' : 'visible'
      }
    },
    reportElementId() {
      return this.learnosityReportName
    },
    hasNextPart() {
      return this.pagination.next_page != null
    },
    hasPrevPart() {
      return this.pagination.previous_page != null
    }
  },
  async mounted() {
    const itemsScript = document.createElement('script');
    itemsScript.src = '//reports.learnosity.com?v2023.3.LTS';
    itemsScript.addEventListener('load', () => {
      this.loadReport()
    });
    document.head.appendChild(itemsScript);
  },
  methods:{
    async loadReport(page){
      this.reportLoading = true
      this.learnosityVisible = false
      try {
        let monitoring_params = {
          sectionId: this.sectionId,
          assessmentSetId: this.assessmentSetId,
          assessmentTypeId: this.assessmentTypeId,
          subjectName: this.subjectName,
          courseType: this.courseType,
          selectedDifficulty: this.selectedDifficulty.toLowerCase(),
          page: page,
          perPage: this.perPage,
          query: this.search,
        }
        let response = await StaffAssessmentApi.liveMonitoring(monitoring_params)

        this.pagination = response.meta
        if (this.pagination.total_count === 0) {
          this.addNotification('warning', 'There is no data for this search filter.')
        } else {
          window.LearnosityReports.init(response.response);
          this.learnosityVisible = true
        }
      } catch (error){
        this.addNotification('error', 'Could not load the Live Monitoring.')
      } finally {
        this.reportLoading = false
      }
    },
  },

}
</script>
import HorizonApi from '@/api/horizon_api'

const STAFF_ASSIGNMENT_SCHEDULES_PATH = `/api/staff/assignment_schedules`

export default {
  getAssignmentSchedules(data){
    return HorizonApi.post(`${STAFF_ASSIGNMENT_SCHEDULES_PATH}/class_section_schedule`, {'assignment_schedule': data})
  },
  createAssignmentSchedule(data){
    return HorizonApi.post(`${STAFF_ASSIGNMENT_SCHEDULES_PATH}`, {'assignment_schedule': data})
  },
  updateAssignmentSchedule(schedule_id, data){
    return HorizonApi.put(`${STAFF_ASSIGNMENT_SCHEDULES_PATH}/${schedule_id}`, {'assignment_schedule': data})
  },
  deleteAssignmentSchedule(schedule_id){
    return HorizonApi.delete(`${STAFF_ASSIGNMENT_SCHEDULES_PATH}/${schedule_id}`)
  }
}
<template>
  <v-container fluid>
    <app-header
      :title="pageTitle"
    />

    <v-row>
      <v-text-field
        v-if="!reportLoading"
        v-model="search"
        placeholder="Search by Student Name"
        prepend-inner-icon="mdi-magnify"
        hide-details
        solo
        flat
        class="input-default input-default__table"
      />
    </v-row>

    <v-row class="my-5" id="learnosityContainer">        
      <v-skeleton-loader
          v-if="reportLoading"
          type="table-thead, table-tbody"
          width="100%"
          height="300"
        />

      <div
        id="report_response_analysis_by_item"
        :class="learnosityVisibility"
      />
    </v-row>

    <v-row class="d-flex justify-space-around">
      <v-btn 
        v-if="hasPrevPart"
        class="btn-default btn-default__secondary"
        @click="page-=1">
        Previous
      </v-btn>

      <v-btn 
        v-if="hasNextPart "
        class="btn-default btn-default__secondary"
        @click="page+=1">
        Next
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers} from "vuex";
const { mapGetters: mapAssessmentGetters } = createNamespacedHelpers('assessments')

import AppHeader from "@/components/shared/AppHeader.vue";

import { StaffStudentResultsApi } from '@/api'

import debounce from 'lodash/debounce'

export default {
  components: {
    AppHeader
  },

  props: {
    assessmentId: {
      type: [Number, String],
      required: true
    },

    sectionId: {
      type: [Number, String],
      required: false,
      default: null
    },

    sections: {
      type: Array,
      required: false,
      default: null
    },

    year: {
      type: [Number, String],
      required: false,
      default: null
    },

    gradeLevels: {
      type: Array,
      required: false
    }
  },
  
  data() {
    return {
      reportLoading: true,
      learnosityVisible: true,
      page: 1,
      pagination: {},
      search: null,
      assessmentName: ''
    }
  },

  watch: {
    page() {
      this.loadReport(this.page)
    },
    search: {
      handler: debounce(function () {
        this.page = 1
        this.loadReport(this.page)
      }, 500)
    },
  },

  computed: {
    ...mapAssessmentGetters(["getAssessmentNameById"]),
    learnosityVisibility() {
      return {
        visibility: this.learnosityVisible ? 'hidden' : 'visible'
      }
    },
    pageTitle() {
      if (this.assessmentName !== '') {
        return `${this.assessmentName} - Response Analysis By Item`  
      } else {
        return "Response Analysis By Item"
      }
    },
    hasNextPart() {
      return this.pagination.next_page != null
    },
    hasPrevPart() {
      return this.pagination.previous_page != null
    }
  },

  async mounted() {
    const itemsScript = document.createElement('script');
    itemsScript.src = '//reports.learnosity.com?v2023.3.LTS';
    itemsScript.addEventListener('load', () => {
      this.loadReport(1)
    });
    document.head.appendChild(itemsScript);
    this.assessmentName = this.getAssessmentNameById(this.assessmentId)
  },

  methods: {
    async loadReport(page) {
      this.reportLoading = true
      this.learnosityVisible = false
      
      let sectionIds = null
      if (this.sectionId) {
        sectionIds = new Array(this.sectionId)
      } else if (this.sections) {
        sectionIds = this.sections
      }

      try {
        let response = await StaffStudentResultsApi.learnosityResponseAnalysisByItem({ 
          assessmentId: this.assessmentId,
          sectionIds: sectionIds,
          gradeLevels: this.gradeLevels,
          schoolYear: this.year,
          page: page,
          query: this.search
        })

        this.pagination = response.pagination
        this.assessmentName = response.assessment_title

        if (this.pagination.total_count === 0) {
          this.addNotification('warning', 'There is no data for this search filter.')
        } else {
          window.LearnosityReports.init(JSON.parse(response.request));   
          this.learnosityVisible = true
        }
      } catch (error) {
        this.addNotification('error', 'Could not load the response analysis.')
      } finally {
        this.reportLoading = false
      }
    },
  }
}
</script>
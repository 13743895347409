<template>
  <v-container fluid>
    <app-header :title="activityName" />
    <v-row>
      <v-text-field
        v-if="!reportLoading"
        v-model="search"
        placeholder="Search by Student Name"
        prepend-inner-icon="mdi-magnify"
        hide-details
        solo
        flat
        class="input-default input-default__table"
      />
    </v-row>
    <v-row class="my-5" id="learnosityContainer">       
      <v-skeleton-loader
          v-if="reportLoading"
          type="table-thead, table-tbody"
          width="100%"
          height="300"
        />
      <div
        id="response-analysis-by-item-report"
        :class="learnosityVisibility"
      />
    </v-row>
  </v-container>
</template>
<script>
import AppHeader from "@/components/shared/AppHeader.vue"
import debounce from "lodash/debounce"
import { StaffCourseActivitiesApi } from '@/api'

export default {
  components: {
    AppHeader
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    courseId: {
      type: [Number, String],
      required: false
    },
    lessonId: {
      type: [Number, String],
      required: false
    },
    activityId: {
      type: [Number, String],
      required: false
    },
    sectionId: {
      type: [Number, String],
      required: false
    }
  },
  data(){
    return {
      reportLoading: true,
      learnosityVisible: true,
      pagination: {},
      search: null,
      activityName: 'Item Analysis',
      learnosityReportName: "response-analysis-by-item-report"
    }
  },
  computed: {
    learnosityVisibility() {
      return {
        visibility: this.learnosityVisible ? 'hidden' : 'visible'
      }
    },
    reportElementId() {
      return this.learnosityReportName
    },
  },
  async mounted() {
    const itemsScript = document.createElement('script');
    itemsScript.src = '//reports.learnosity.com?v2023.3.LTS';
    itemsScript.addEventListener('load', () => {
      this.loadReport(1)
    });
    document.head.appendChild(itemsScript);
  },
  watch: {
    search: {
      handler: debounce(function () {
        this.loadReport()
      }, 500)
    },
  },
  methods: {
    async loadReport(){
      this.reportLoading = true
      this.learnosityVisible = false
      try {
        let data = {
          course_id: this.courseId,
          lesson_id: this.lessonId,
          activity_id: this.activityId,
          section_id: this.sectionId,
          query: this.search,
        }
        let response = await StaffCourseActivitiesApi.getItemAnalysisConf(data)
        
        window.LearnosityReports.init(JSON.parse(response.response));
        this.learnosityVisible = true
        
      } catch (error) {
        this.addNotification('error', 'Could not load the response analysis.')
      } finally {
        this.reportLoading = false
      }
    },
  },
}
</script>
<template>
  <div
    class="gradebook-table-container"
    :class="getTableHeight()"
  >
    <table class="table table-primary w-full">
      <thead class="table-header">
        <tr class="table-header-row-0">
          <th
            :colspan="3"
            class="table-header-spacer table-col-sticky"
          />
          <th
            v-for="section in tableHeaders.sections"
            :key="section.id"
            class="section-outer-div"
            :colspan="section.span"
          >
            <span class="white--text">{{ section.title }}</span>
          </th>
        </tr>
        <tr class="table-header-row-1">
          <th
            :colspan="3"
            class="table-header-spacer table-col-sticky"
          />
          <th
            v-for="lesson in tableHeaders.lessons"
            :key="lesson.id"
            class="lesson-name-div"
            :colspan="lesson.span"
          >
            {{ lesson.title }}
          </th>
        </tr>
        <tr class="table-header-row-2">
          <th
            v-for="(title, idx) in tableHeaders.summary"
            :key="'table-col-'+idx"
            class="table-header-spacer table-col-sticky"
            :class="'table-col-'+idx"
          >
            {{ title }}
          </th>
          <th
            v-for="(activity, idx) in tableHeaders.activities"
            :key="'table-col-'+(parseInt(idx)+3)"
            class="gradebook-activity-div"
            :class="'table-col-'+(parseInt(idx)+3)"
          >
            <div class="text-center">
              <div>{{ snakeToHuman(activity.activity_name) }}</div>
              <div class="mt-2">
                <v-btn
                  class="item-analysis-link no-shadow"
                  @click="viewItemAnalysis(activity.lesson_id,activity.activity_id)"
                >
                  <span class="link-text">Item Analysis</span>
                </v-btn>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, idx) in tableData"
          :key="'table-data-row-'+idx"
          :class="'table-data-row-'+idx"
        >
          <td class="table-col-0 table-col-sticky">
            {{ data.student.name }}
          </td>
          <td class="table-col-1 table-col-sticky">
            {{ data.summary.activities_completed }}
          </td>
          <td class="table-col-2 table-col-sticky">
            {{ sumScorePercentAverage(data.summary.sum_score_percentage, data.summary.activities_completed) }}
          </td>
          <td
            v-for="(response, idx) in data.responses"
            :key="'table-col-'+(parseInt(idx)+3)"
            :class="'table-col-'+(parseInt(idx)+3)"
          >
            <div class="d-flex justify-center">
              {{ responseShow(response) }}
              <v-img
                v-if="hasResponse(response) && editMode"
                src="@/assets/icons/trash-1.png"
                alt="icon-add"
                max-width="19"
                max-height="19"
                @click="onOpenDialogDeleteConfirmation(response)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      :message="`Are you sure you want to delete the Response? This action cannot be undone.`"
      @confirmDeletion="confirmDeleteResponse()"
      @closeDialog="closeDialogDeleteConfirmation()"
    />
  </div>
</template>
<script>
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue"
import {StaffCourseActivitiesApi} from '@/api'

export default {
  components: {
    DialogDeleteConfirmation
  },
  props: {
    gradebookTableData: {
      type: Object,
      default() {
        return {}
      }
    },
    courseId: {
      type: [String, Number],
      default: 0
    },
    sectionId: {
      type: [String, Number],
      default: 0
    },
    editMode: {
      type: Boolean,
      default: null
    },
  },
  data() {
    return {
      tableHeaders: [],
      tableData: [],
      responseToDelete: {},
      dialogDeleteConfirmation: false,
    }
  },
  mounted() {
    this.tableHeaders = this.gradebookTableData.table_headers
    this.tableData = this.gradebookTableData.table_data
  },
  methods: {

    viewItemAnalysis(lesson_id, activity_id) {
      let pathName = "StaffActivityAnalysisByItem"
      const routeData = this.$router.resolve({
        name: pathName,
        params: {
          courseId: this.courseId,
          lessonId: lesson_id,
          activityId: activity_id,
          sectionId: this.sectionId,
        },
      })
      window.open(routeData.href, '_blank')
    },

    closeDialogDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
    },

    confirmDeleteResponse() {
      this.responseDelete(true)
      this.dialogDeleteConfirmation = false
    },

    responseDelete(delete_approval) {
      console.log('response delete:', delete_approval)
      if (delete_approval === true) {
        StaffCourseActivitiesApi.deleteResponse(this.courseId, this.sectionId, this.responseToDelete.he_course_student_response_id)
            .then((response) => {
              console.log('delete response', response);
              this.$emit('table-update');
            }).catch((error) => {
          console.log(error);
        })
      }
      this.responseToDelete = {}
    },

    onOpenDialogDeleteConfirmation(response) {
      this.responseToDelete = response
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true
      })
    },

    hasResponse(response) {
      return (response.he_course_student_response_id && response.status == 'Completed')
    },

    responseShow(response) {
      let show = '-'
      if (response.he_course_student_response_id && response.status == 'Completed') {
        if (response.max_score > 0) {
          show = Math.round(response.score_percentage) + '%'
        } else {
          show = "100%"
        }
      }
      return show
    },

    sumScorePercentAverage(sum_score_percent, activities_completed) {
      if (activities_completed == 0) return '0%'
      return String(Math.round(sum_score_percent / activities_completed)) + '%'
    },

    snakeToHuman(str) {
      let pcs = str.split('_')
      pcs = pcs.map(pc => pc.charAt(0).toUpperCase() + pc.substring(1))

      return pcs.join(' ')
    },

    getTableHeight() {
      if (this.tableData.length < 18) return undefined

      return 'table-height-800'
    }
  },
}
</script>
<style scoped lang="scss">
.section-outer-div {
  background-color: #345165;
  border-right: 1px solid #696969;
  height: 50px;
}

.lesson-name-div {
  width: 200px;
  height: 80px;
  text-align: center;
  border: 1px solid #696969;
  border-style: none solid solid none;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.gradebook-activity-div {
  width: 100px;
  height: 76px;
  text-align: center;
  background-color: #e9e9e9;
  border-top: 1px #696969;
  border-bottom: 2px solid #363636;
  border-left: 1px #696969;
  border-right: 1px solid #696969;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  line-height: 16px;
  text-decoration: none;
}

.item-analysis-link {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 2px 16px;
  display: inline-block;
  text-decoration: none;
  color: #333;
  height: 25px !important;
  padding: 0px 5px !important;
}

.link-text {
  font-size: 10px;
}

.no-shadow {
  box-shadow: none !important;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border: none;

  th, td {
    border: none;
  }
}

.gradebook-table-container {
  position: relative;
  overflow: auto;
  width: 100%;
  max-width: 100%;
}

.table-height-800 {
  height: 800px;
  max-height: 800px;
}

.table-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}

.table-header-spacer {
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  left: 0px;
}

.table-col-0 {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
  left: 0px;
}

.table-col-1 {
  min-width: 110px;
  max-width: 110px;
  width: 110px;
  left: 200px;
}

.table-col-2 {
  min-width: 110px;
  max-width: 110px;
  width: 110px;
  left: 310px;
}

.table-col-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

.table-header-spacer {
  border: none;
  background-color: white;
}

.table-header-row-0 {
  .table-header-spacer {
    border-right: 2px solid black;
  }

  th:not(.table-header-spacer) {
    font-size: 20px;
    border-right: 1px solid #696969;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
}

.table-header-row-1 {
  background-color: #bbb;
  color: black;

  .table-header-spacer {
    border-bottom: 1px solid black;
    border-right: 2px solid black;
  }

  th:not(.table-header-spacer) {
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    border-right: 1px solid #696969;
    border-bottom: 1px solid black;
  }
}

.table-header-row-2 {
  background-color: #e9e9e9;
  color: black;

  .table-col-sticky.table-col-2 {
    border-right: 2px solid black;
  }

  .table-col-sticky {
    font-weight: 600;
    background-color: #e9e9e9;
    color: black;
  }

  th {
    min-width: 110px;
    max-width: 110px;
    width: 110px;
    font-weight: 400;
    vertical-align: middle;
    font-size: 14px;
    padding: 10px 5px 10px 5px;
    border-right: 1px solid #696969;
    border-bottom: 2px solid black;
  }
}

tr[class^='table-data-row-'] {
  background-color: white;
  color: black;

  span {
    font-size: 10px;
  }

  td {
    height: 30px;
    padding: 5px 10px 5px 10px;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border-right: 1px solid #696969;
    border-bottom: 1px solid #696969;
  }

  .table-col-sticky.table-col-2 {
    border-right: 2px solid black;
  }

  .table-col-sticky.table-col-0 {
    text-align: right;
    color: #345165;
  }

  .table-col-sticky {
    background-color: white;
    color: black;
    font-weight: 600;
  }
}
</style>

<template>
  <div v-if="section">
    <v-container fluid>
      <app-header :title="section?.displayName" />
    </v-container>

    <app-tabs-secondary
      :tabs="tabs"
      :active-tab="tabSelected"
      @onTabChange="onLoadCategory(...arguments)"
    />
    <v-container
      class="pt-0"
      fluid
    >
      <router-view />
    </v-container>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex"

const {mapState} = createNamespacedHelpers('staffSections')
import AppHeader from "@/components/shared/AppHeader.vue";
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";

export default {
  name: "StaffCourseActivitiesClass",
  components: {
    AppHeader,
    AppTabsSecondary
  },
  data() {
    return {
      tableLoading: true,
      sectionId: null,
      section: {},
      tabSelected: null,
      tabs: [
        {
          tabText: 'Course Activities',
          text: 'Assessment Schedule',
          name: 'StaffCourseActivitiesCourse',
        },
        {
          tabText: 'Assignments',
          text: 'Assessments',
          name: 'StaffCourseActivitiesAssignments',
        },
        {
          tabText: 'Gradebook',
          text: 'Gradebook',
          name: 'StaffCourseActivitiesGradebook',
        }
      ],
    }
  },

  watch: {
    $route() {
      this.setActiveTab()
    }
  },

  mounted() {
    this.sectionId = this.$route.params.sectionId;
    this.heCourseTemplateId = this.$route.params.heCourseTemplateId;
    this.section = this.allSections().find(section => section.id == this.sectionId)
    this.setActiveTab();
  },

  methods: {
    ...mapState(['allSections']),

    onLoadCategory(tab) {
      this.$router.push({
        name: this.tabs[tab].name,
        params: {
          sectionId: this.$route.params.sectionId,
          courseTemplate: this.$route.params.courseTemplate,
        }
      })
    },

    setActiveTab() {
      for (let [index, tab] of this.tabs.entries()) {
        if (tab.name === this.$route.name) {
          this.tabSelected = index
        }
      }
    }
  },
}
</script>

